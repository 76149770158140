import { Component, Input, OnChanges } from '@angular/core';

import { Instrument } from '../../../entities/instrument';
import { trailingZeros } from '../../../utils/number';
import BigNumber from 'bignumber.js';

@Component({
  selector: 'cz-instrument-amount',
  templateUrl: './instrument-amount.component.html',
  styleUrls: ['./instrument-amount.component.scss'],
})
export class InstrumentAmountComponent implements OnChanges {
  @Input() amount?: BigNumber | number | null;
  @Input() instrument: Instrument;
  @Input() highlightTrailingZeros = true;
  @Input() lenientRounding = false;
  @Input() ellipsis = false;

  displayedAmount: string;
  trailingZeros: string;

  ngOnChanges(): void {
    const amount = this.roundAmount();
    if (amount) {
      if (this.highlightTrailingZeros) {
        const display = trailingZeros(amount);
        this.displayedAmount = display.num;
        this.trailingZeros = display.zeros;
      } else {
        this.displayedAmount = amount;
        this.trailingZeros = '';
      }
    }
  }

  private roundAmount(): string | null {
    if (this.instrument) {
      if (this.lenientRounding) {
        return this.instrument.displayAmount(
          this.amount,
          Math.max(this.instrument.maxQuantityPrecision, new BigNumber(this.amount ?? 0).decimalPlaces() ?? 0)
        );
      } else {
        return this.instrument.displayAmount(this.amount);
      }
    } else {
      return null;
    }
  }
}
