import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[czNoNumericSpin]',
})
export class NoNumericSpinDirective {
  constructor(el: ElementRef<HTMLElement>) {
    el.nativeElement.addEventListener('mousewheel', (evt: Event) => {
      evt.preventDefault();
    });
    el.nativeElement.addEventListener('keydown', (evt: KeyboardEvent) => {
      if (evt.keyCode === 38 || evt.keyCode === 40 || evt.keyCode === 69) {
        evt.preventDefault();
      }
    });
  }
}
