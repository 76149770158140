import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';

import { defined } from 'core';

@Component({
  selector: 'app-external-redirect',
  templateUrl: './external-redirect.component.html',
  styleUrls: ['./external-redirect.component.scss'],
})
export class ExternalRedirectComponent implements OnChanges {
  @Input() partner?: string | null;
  @Input() token: string | null;
  @Input() newTab = false;

  @ViewChild('externalRedirect') externalRedirect: ElementRef;

  get target() {
    return this.newTab ? '_blank' : '';
  }

  ngOnChanges(): void {
    if (defined(this.partner) && defined(this.token)) {
      // wait for the template to finish rendering before submitting
      setTimeout(() => this.externalRedirect.nativeElement.submit(), 0);
    }
  }
}
