import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NativeEventListenerService {
  private functionMap = new Map<string, Array<EventListenerOrEventListenerObject>>();

  addEventListener(event: string, func: EventListenerOrEventListenerObject) {
    let listeners = this.functionMap.get(event);
    if (!listeners) {
      listeners = new Array<EventListenerOrEventListenerObject>();
      this.functionMap.set(event, listeners);
    }
    listeners.push(func);
    const eventType: string = event.split('.')[0];
    window.addEventListener(eventType, func, false);
    console.log(`Registered callback for ${event}, underlying type ${eventType}`);
  }

  removeEventListener(event: string) {
    const eventType: string = event.split('.')[0];
    const funcs = this.functionMap.get(event);
    if (funcs) {
      for (let i = 0; i < funcs.length; i++) {
        const func: EventListenerOrEventListenerObject = funcs[i];
        window.removeEventListener(eventType, func);
      }

      this.functionMap.delete(event);
    }
    console.log(`Removed all callbacks for ${event}, underlying type ${eventType}`);
  }
}
