import { Component, OnInit, AfterViewInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { filter, skip } from 'rxjs/operators';
import { faArrowUpRightFromSquare, faEnvelope, faEnvelopeOpen, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { SessionService } from 'services/session.service';
import { BroadcastService, Theme } from 'services/broadcast.service';
import { MessageService } from 'services/message/message.service';
import { HeaderService } from 'services/header.service';
import { NotificationSocketService } from 'services/notification.socket/notification.socket.service';
import { SystemNotificationsService } from 'services/system-notifications/system.notifications.service';
import { ResourceResponse, LoginSuccess, AuthService } from 'core';
import { SsnService } from 'services/ssn.service';

export enum Platform {
  IOS = 'IOS',
  Android = 'Android',
  Other = 'Other',
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly Platform = Platform;
  readonly Theme = Theme;

  readonly faExternal = faArrowUpRightFromSquare;

  @Input() theme: Theme;
  @Input() simple: boolean;

  @Output() themeChange = new EventEmitter<Theme>();

  $loggedIn = this.authService.$loggedIn;
  showMessages = false;
  platform: Platform | null = null;
  themeable: boolean;

  forExternal?: string;
  authToken?: Promise<string>;

  get faEnvelope(): IconDefinition {
    return this.showMessages ? faEnvelopeOpen : faEnvelope;
  }

  constructor(
    public readonly authService: AuthService,
    private readonly router: Router,
    private readonly messageService: MessageService,
    private readonly sessionService: SessionService,
    private readonly broadcastService: BroadcastService,
    private readonly headerService: HeaderService,
    private readonly notificationSocketService: NotificationSocketService,
    private readonly systemNotificationService: SystemNotificationsService,
    ssnService: SsnService
  ) {
    this.broadcastService.logoutSource$.pipe(filter((loggedIn) => loggedIn)).subscribe(() => ssnService.obtainSsnIfRequired());
  }

  ngOnInit(): void {
    this.platform = this.getPlatform();
    this.themeable = !this.simple;
    this.broadcastService.broadcastThemeChange(this.theme);
    this.connectNotificationSocket();
    this.headerService.handleMouseWheelEvent();
    this.systemNotificationService.start();
    this.broadcastService.loginAfterVerificationSource$.subscribe(this.loginAfterEmailVerification.bind(this));
  }

  ngAfterViewInit(): void {
    const url = window.location.href;
    if (url.indexOf('sign_up') > -1) {
      this.launchSignup();
    }

    if (window.location.href.indexOf('landing-signin') > -1) {
      this.router.navigateByUrl('/');
    }

    // This subscription does not leak as the header component is never removed and recreated
    this.$loggedIn
      .pipe(
        skip(1), // only redirect on logged-in state changes *after* the initial page load
        filter((loggedIn) => !loggedIn)
      )
      .subscribe((_) => this.router.navigateByUrl('/login'));
  }

  private launchSignup(): void {
    if (this.authService.loggedIn) {
      this.authService.clearAuthData();
      location.reload();
    } else {
      this.router.navigateByUrl('signup');
    }
  }

  private getPlatform(): Platform {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      return Platform.Android;
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      return Platform.IOS;
    } else {
      return Platform.Other;
    }
  }

  private connectNotificationSocket(): void {
    if (this.authService.loggedIn) {
      this.notificationSocketService.connectWebSocket();
    }
  }

  ngOnDestroy(): void {
    if (this.authService.loggedIn) {
      this.notificationSocketService.disconnectWebSocket();
    }
  }

  toggleTheme(): void {
    this.theme = this.theme === Theme.night ? Theme.day : Theme.night;

    this.broadcastService.broadcastThemeChange(this.theme);
    this.themeChange.next(this.theme);
  }

  private loginAfterEmailVerification(verification: ResourceResponse<LoginSuccess>): void {
    if (verification.isSuccess) {
      this.authService.storeAuthDataOnLogin(verification.responseObject);
      this.connectNotificationSocket();
      this.broadcastService.broadcastLogoutChange(true);
      setTimeout(() => this.messageService.success(verification.message));
      this.router.navigateByUrl('settings');
    } else {
      this.router.navigateByUrl('login');
    }
  }

  logout(): void {
    this.sessionService.logout().subscribe(() => {
      this.authService.clearAuthData();
      setTimeout(() => this.messageService.success('Logout Successfully'));
      this.broadcastService.broadcastLogoutChange(false);
      this.router.navigateByUrl('/');
      this.notificationSocketService.disconnectWebSocket();
    });
  }

  openNav(): void {
    document.getElementById('myNav')!.style.width = '70%';
  }

  closeNav(): void {
    document.getElementById('myNav')!.style.width = '0%';
  }

  goExternal(partner: string) {
    // The external redirect component will wait until the token is available then force a browser redirect
    this.forExternal = partner;
    this.authToken = this.authService.getToken();
  }
}
