import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BehaviorSubject } from 'rxjs';

export interface Scrollable<IdType> {
  id: IdType;
}

export abstract class ScrollLoader<IdType, ScrollType extends Scrollable<IdType>> {
  private _scrollSubject = new Array<ScrollType>();
  private _scrollRequests = new Array<IdType | null>();
  private _dataLoadInProgress = false;
  private _haveAllData = false;
  private _haveData = false;
  private _viewport: CdkVirtualScrollViewport;
  $dataSubject = new BehaviorSubject<Array<ScrollType>>(this._scrollSubject);

  constructor(public extraRows: Array<ScrollType> = [], private rowComparator?: (r1: ScrollType, r2: ScrollType) => number) {}

  public set viewport(viewport: CdkVirtualScrollViewport) {
    this._viewport = viewport;
    if (this._viewport) {
      this._viewport.elementRef.nativeElement.onscroll = this.elementScroll.bind(this);
    }
  }

  public get viewport(): CdkVirtualScrollViewport {
    return this._viewport;
  }

  public getId(_row: number, item: ScrollType): IdType {
    return item.id;
  }

  public findById(id: IdType): ScrollType | undefined {
    return this._scrollSubject.find((item, row) => this.getId(row, item) === id);
  }

  public find(predicate: (_: ScrollType) => boolean): ScrollType | undefined {
    return this._scrollSubject.find((item, _) => predicate(item));
  }

  private elementScroll(): void {
    const scrollOffset = this._viewport.measureScrollOffset('bottom');

    if (scrollOffset < 100 && !this.haveAllData) {
      // Getting near the end, load the next page.
      const lastId = this.getLastId();
      if (lastId !== null) {
        this.scroll(lastId);
      }
    }
  }

  /**
   * Pass `null` to scroll to the top
   */
  public scroll(id: IdType | null): void {
    if (!this._haveAllData) {
      if (this._scrollRequests.indexOf(id) === -1) {
        this._scrollRequests.push(id);
        if (!this._dataLoadInProgress) {
          this.loadNextRequest();
        }
      }
    }
  }

  public get scrollSubject(): ScrollType[] {
    return this._scrollSubject;
  }

  public get haveAllData(): boolean {
    return this._haveAllData;
  }

  public get haveData(): boolean {
    return this._haveData;
  }

  public get dataLoadInProgress(): boolean {
    return this._dataLoadInProgress;
  }

  protected prepend(item: ScrollType): ScrollType {
    // Due to https://github.com/angular/components/issues/14635 the following does NOT work
    // this._scrollSubject.unshift(item);
    // TODO A better solution from Angular's perspective is to replace this with an Observable<Array>
    this._scrollSubject = [item, ...this._scrollSubject];
    this._haveData = true;
    this.$dataSubject.next(this._scrollSubject);
    return item;
  }

  public replace(idToReplace: IdType, replacement: ScrollType): void {
    // Due to https://github.com/angular/components/issues/14635 we have to rebuild the array rather
    // than replacing the relevant index in the array.
    const newScrollSubject = new Array<ScrollType>();
    this._scrollSubject.forEach((d) => {
      if (d.id === idToReplace) {
        newScrollSubject.push(replacement);
      } else {
        newScrollSubject.push(d);
      }
    });

    this._scrollSubject = newScrollSubject;
    this.$dataSubject.next(this._scrollSubject);
  }

  /**
   * Insert the item into the first place where the `where` condition returns true
   */
  public insertWhere(item: ScrollType, where: (item: ScrollType) => boolean): void {
    let at = this._scrollSubject.findIndex(where);
    if (at < 0) {
      at = this._scrollSubject.length;
    }
    this._scrollSubject.splice(at, 0, item);

    // https://github.com/angular/components/issues/14635 again!
    this._scrollSubject = [...this._scrollSubject];
    this._haveData = true;
    this.$dataSubject.next(this._scrollSubject);
  }

  public removeWhere(where: (item: ScrollType) => boolean): void {
    this._scrollSubject = this._scrollSubject.filter((item, _) => !where(item));
    this._haveData = this._scrollSubject.length > 0;
    this.$dataSubject.next(this._scrollSubject);
  }

  public reset(): void {
    this._scrollSubject = new Array<ScrollType>();
    this.extraRows.forEach((r) => this._scrollSubject.push(r));
    this._haveAllData = false;
    this._haveData = false;
    this._scrollRequests = new Array<IdType>();
    this.$dataSubject.next(this._scrollSubject);
  }

  private getLastId(): IdType | null {
    if (this.extraRows.length === 0) {
      return this._scrollSubject.length > 0 ? this._scrollSubject[this._scrollSubject.length - 1].id : null;
    } else {
      const extraIds = new Set<IdType>();
      this.extraRows.forEach((r) => extraIds.add(r.id));
      for (let i = this._scrollSubject.length - 1; i >= 0; i--) {
        if (!extraIds.has(this._scrollSubject[i].id)) {
          return this._scrollSubject[i].id;
        }
      }
      return null;
    }
  }

  private loadNextRequest(): void {
    this._dataLoadInProgress = true;
    const toLoad = this._scrollRequests[0];
    this.loadData(toLoad).then(
      (data) => {
        if (this._scrollRequests.indexOf(toLoad) == -1) {
          // If the id has been removed from the request list the scroller was
          // reset while we were waiting for the data from the server. In which
          // case we can ignore the data.
          return;
        }

        data.forEach((item) => {
          if (this.loadedRowDoesNotExist(item)) {
            this._scrollSubject = this._scrollSubject.concat([item]);
          }
        });
        if (this.rowComparator) {
          this._scrollSubject = this._scrollSubject.sort(this.rowComparator);
        }
        this.$dataSubject.next(this._scrollSubject);
        if (data.length > 0) {
          this._haveData = true;
        }
        this._scrollRequests.shift();
        this._dataLoadInProgress = false;
        if (this._scrollRequests.length > 0) {
          this.loadNextRequest();
        } else if (data.length == 0) {
          this._haveAllData = true;
        }
      },
      (error) => {
        console.log(error);
        this._dataLoadInProgress = false;
      }
    );
  }

  protected loadedRowDoesNotExist(item: ScrollType): boolean {
    return this._scrollSubject.filter((ci) => ci.id == item.id).length == 0;
  }

  /**
   * Load from the given ID, if any
   */
  protected abstract loadData(id: IdType | null): Promise<ScrollType[]>;
}
