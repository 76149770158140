export enum CommonEnums {
  Deposit = 'DEPOSIT',
  Withdraw = 'WITHDRAW',
  All = 'ALL',
  Received = 'RECEIVED',
  Sent = 'SENT',
  Password = 'password',
  Text = 'text',
  Passport = 'PASSPORT',
  Crypto = 'CRYPTO',
  Fiat = 'FIAT',
}
