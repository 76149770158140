import { Component, Input } from '@angular/core';

@Component({
  selector: 'cz-numeric-display',
  templateUrl: './numeric-display.component.html',
  styleUrls: ['./numeric-display.component.scss'],
})
export class NumericDisplayComponent {
  @Input() num: string;
  @Input() zeros: string;

  get hasZeros(): boolean {
    return this.zeros?.length > 0;
  }
}
