import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import * as R from 'runtypes';
import { Intersect, Literal, Static, Union } from 'runtypes';

import { URLS } from './app.constant';
import { ResourceResponse, SuccessOnlyResponse } from '../utils/utils';
import { LoginSuccess, LoginTypes, TwoFaSetup } from './auth.service';
import { KycLevel, KycStatus } from './auth.service';
import { Enum } from '../utils/runtypes';

const TwoFaTokens = R.Record({
  confirmationToken: R.String,
  loginConfirmationToken: R.String,
});

const DirectLogin = LoginSuccess.extend({ loginType: Literal(LoginTypes.DIRECT), force2FA: Literal(false) });
export type DirectLogin = Static<typeof DirectLogin>;

const Force2FA = Intersect(R.Record({ loginType: Literal(LoginTypes.DIRECT), force2FA: Literal(true) }), TwoFaTokens, TwoFaSetup);
export type Force2FA = Static<typeof Force2FA>;

const SimpleLogin = Union(DirectLogin, Force2FA);
export type SimpleLogin = Static<typeof SimpleLogin>;

const LoginRequires2FA = Intersect(
  R.Record({
    loginType: Literal(LoginTypes.CODE_2FA),
  }),
  TwoFaTokens
);
export type LoginRequires2FA = Static<typeof LoginRequires2FA>;

const LoginConfirmation = Union(SimpleLogin, LoginRequires2FA);
export type LoginConfirmation = Static<typeof LoginConfirmation>;

export type ReferralTokenInfo = {
  status: ReferralTokenStatus;
  name: string;
  email: string;
  externalIdOwner: string;
};

export enum ReferralTokenStatus {
  VALID = 'VALID',
  NOT_FOUND = 'NOT_FOUND',
  USED = 'USED',
}

export type BasicAuth = {
  email: string;
  password: string;
};

export type Frontend = 'trade' | 'prices';

const UserData = R.Record({
  email: R.String,
  accountHandle: R.String.nullable(),

  firstName: R.String.nullable(),
  middleName: R.String.nullable(),
  lastName: R.String.nullable(),

  dob: R.String.nullable(),

  addressLine1: R.String.nullable(),
  addressLine2: R.String.nullable(),
  city: R.String.nullable(),
  stateId: R.Number.nullable(),
  stateCode: R.String.nullable(),
  zipCode: R.String.nullable(),
  countryId: R.Number.nullable(),
  isoCode3: R.String.nullable(),

  employmentStatus: R.String.nullable(),
  preTaxAnnualIncomeFrom: R.Number.nullable(),
  preTaxAnnualIncomeTo: R.Number.nullable(),

  countryCode: R.Number.nullable(),
  mobileNumber: R.String.nullable(),
  mobileVerified: R.Boolean,

  kycStatus: Enum(KycStatus).nullable(),
  kycLevel: Enum(KycLevel).nullable(),
  kycSubmitted: R.Boolean,

  socialSecurityNumber: R.String.nullable(),
  passportUploadCount: R.Number,
  socialSecurityNumberCount: R.Number,

  termsAndConditionAccepted: R.Boolean,
  legalTermsAccepted: R.Boolean,
  responsibilityAccepted: R.Boolean,

  profileSubmitted: R.Boolean,

  signupFrom: R.String.nullable(),
});
export type UserData = Static<typeof UserData>;

export type LimitedUserData = {
  firstName: string;
  lastName: string;
  middleName: string | null;
  countryId: number;
  mobileNumber: string;
  accountHandle: string;
};

const ApiUserDataResponse = SuccessOnlyResponse(R.Record({ successData: UserData }));

const LoginConfirmationResponse = SuccessOnlyResponse(LoginConfirmation);

/**
 * This class has subclasses and thus can not have any non-static state as any client within the core library would get
 * a different instance of that state.
 */
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(protected readonly http: HttpClient) {}

  public signIn(user: BasicAuth): Observable<SuccessOnlyResponse<LoginConfirmation>> {
    const dataApi = {
      emailId: user.email,
      password: user.password,
      role: 'User',
      directAllowed: true,
    };

    return this.http.post(URLS.signIn, dataApi).pipe(map(LoginConfirmationResponse.check));
  }

  public logout(): Observable<unknown> {
    return this.http.delete(URLS.logout);
  }

  checkReferralToken(referralToken: string): Observable<SuccessOnlyResponse<ReferralTokenInfo>> {
    return this.http.post<SuccessOnlyResponse<ReferralTokenInfo>>(URLS.checkReferralToken, { referralToken });
  }

  signup(
    signUp: BasicAuth,
    referralToken: string,
    sendReferalToken: boolean,
    signupFrom: string | null,
    frontend?: Frontend
  ): Observable<ResourceResponse<void>> {
    let url = URLS.signUp;
    if (sendReferalToken) {
      url += '?referralToken=' + referralToken;
    }
    return this.http.post<ResourceResponse<void>>(url, {
      ...signUp,
      // The validation ignores outer-whitespace so trim now
      email: signUp.email.trim(),
      frontend,
      signupFrom,
    });
  }

  signupWithOtp(signUp: BasicAuth, referralToken: string, sendReferalToken: boolean): Observable<ResourceResponse<string>> {
    let url = URLS.signUpWithOtp;
    if (sendReferalToken) {
      url += '?referralToken=' + referralToken;
    }
    return this.http.post<ResourceResponse<string>>(url, {
      ...signUp,
      // The validation ignores outer-whitespace so trim now
      email: signUp.email.trim(),
    });
  }

  signupEmailOtpVerification(token: string, otp: string): Observable<ResourceResponse<LoginSuccess>> {
    return this.http.post<ResourceResponse<LoginSuccess>>(URLS.signupEmailOtpVerification, {
      token,
      otp,
    });
  }

  resendSignupEmailOtp(token: string): Observable<ResourceResponse<void>> {
    return this.http.post<ResourceResponse<void>>(URLS.resendSignupEmailOtp, token);
  }

  getUserData(): Observable<SuccessOnlyResponse<{ successData: UserData }>> {
    return this.http.get(URLS.profileUrl).pipe(map(ApiUserDataResponse.check));
  }
}

export const is2FaRequired = (response: LoginConfirmation): response is LoginRequires2FA => response.loginType === LoginTypes.CODE_2FA;
export const isForce2FA = (response: SimpleLogin): response is Force2FA => response.force2FA ?? false;
export const isLoginSuccess = (response: SimpleLogin): response is DirectLogin => !is2FaRequired(response) && !isForce2FA(response);
