import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { merge, from } from 'rxjs';
import { map, filter, mapTo, mergeMap, tap } from 'rxjs/operators';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { URLS } from 'commons/app.constant';
import { SystemNotification } from 'entities/system.notification';
import {
  ApiMarketDataMessage,
  ApiSystemNotificationMessage,
  MarketDataService,
  SubscriptionAction,
  SystemNotificationDTO,
  withoutResetNotifications,
} from 'core';

@Injectable({
  providedIn: 'root',
})
export class SystemNotificationsService {
  private locallyDismissed: number[] = JSON.parse(localStorage.getItem('locallyDismissed') || '[]');

  constructor(private http: HttpClient, private marketDataService: MarketDataService, private toastr: ToastrService) {}

  public start(): void {
    const existingNotifications = this.http.get<Array<SystemNotificationDTO>>(URLS.getAllSystemNotifications).pipe(
      tap(this.trimLocallyDismissed.bind(this)),
      map((data) => data.map(makeNotification)),
      mergeMap((notifications) => from(notifications)) // turn an array into an observable sequence
    );

    const newNotifications = this.marketDataService.subscribe(makeMessage, isSystemNotification).pipe(
      filter(withoutResetNotifications),
      map((msg) => msg.SystemNotificationEvent),
      map(makeNotification)
    );

    merge(existingNotifications, newNotifications)
      .pipe(filter(this.isForDisplay.bind(this)), mergeMap(this.display.bind(this)))
      .subscribe(this.markDismissed.bind(this));
  }

  private isForDisplay(notification: SystemNotification): boolean {
    const now = moment();
    return notification.visibleToTs.isSameOrAfter(now) && !this.locallyDismissed.includes(notification.id);
  }

  private display(notification: SystemNotification) {
    return this.toastr
      .warning(notification.notificationText, undefined, {
        closeButton: true,
        disableTimeOut: true,
        positionClass: 'toast-top-full-width',
      })
      .onHidden.pipe(
        // onHidden claims to pass 'any' but it's really undefined
        mapTo(notification)
      );
  }

  private markDismissed(notification: SystemNotification): void {
    this.locallyDismissed.push(notification.id);
    this.saveLocallyDismissed();
  }

  /* Any locallyDismissed notifications which are no longer active do not need to be remembered */
  private trimLocallyDismissed(activeNotifications: Array<SystemNotificationDTO>) {
    this.locallyDismissed = activeNotifications.map((notification) => notification.id).filter((id) => this.locallyDismissed.includes(id));
    this.saveLocallyDismissed();
  }

  private saveLocallyDismissed() {
    localStorage.setItem('locallyDismissed', JSON.stringify(this.locallyDismissed));
  }
}

// TODO move to the API layer
const makeNotification = (data: SystemNotificationDTO): SystemNotification => ({
  ...data,
  visibleToTs: moment(data.visibleToTs),
});

const makeMessage = (action: SubscriptionAction) => ({
  SystemNotificationRequest: {
    action,
  },
});

const isSystemNotification = (msg: ApiMarketDataMessage): msg is ApiSystemNotificationMessage => 'SystemNotificationEvent' in msg;
