import { first, Observable } from 'rxjs';
import { Lookup3DSResponse } from './threeDSecure.service';

/**
 * The cardinal API is untyped. This is the API we use.
 */
type CardinalAPI = {
  setup: (mode: string, options: { jwt: string }) => Promise<unknown>;
  continue: (mode: string, options: { AcsUrl: string; Payload: string }, orderDetails: { OrderDetails: { TransactionId: string } }) => void;
  on: (event: string, handler: (data: { ActionCode?: string }, jwt?: string) => void) => void;
};

/**
 * And this is the global javascript variable from songbird.js
 */
declare let Cardinal: CardinalAPI;

const DATA_COLLECTION_EVENT = 'payments.setupComplete';
const CHALLENGE_EVENT = 'payments.validated';

export type ChallengeResponse = {
  success: boolean;
  jwt?: string;
};
/**
 * Wrap the Songbird events for data collection in an observable.
 */
export const $dataCollection = (jwt: string) =>
  new Observable((subscribe) => {
    Cardinal.on(DATA_COLLECTION_EVENT, () => {
      subscribe.next();
      subscribe.complete();
    });

    Cardinal.setup('init', { jwt });
  }).pipe(first());

/**
 * Wrap the Songbird events for 3DS challenges in an observable.
 */
export const $challenge = (response: Lookup3DSResponse) =>
  new Observable<ChallengeResponse>((subscribe) => {
    Cardinal.on(CHALLENGE_EVENT, (data, jwt) => {
      if (data?.ActionCode === 'SUCCESS') {
        subscribe.next({ success: true, jwt });
      } else {
        subscribe.next({ success: false });
      }
      subscribe.complete();
    });

    Cardinal.continue(
      'cca',
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        AcsUrl: response.challengeURL!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Payload: response.challengePayload!,
      },
      {
        OrderDetails: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          TransactionId: response.processorTransactionID!,
        },
      }
    );
  }).pipe(first());
