import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject, merge, combineLatest, of, firstValueFrom } from 'rxjs';
import { switchMap, map, first, tap, delay, takeUntil, filter } from 'rxjs/operators';

import { Currency } from '../entities/currency';
import { CurrentMarketValue, SimpleCurrentMarketValue } from '../entities/current.market.value';
import { Instrument } from '../entities/instrument';
import { ObservableStore, Store } from '../utils/store';
import {
  ApiMarketDataMessage,
  ApiTwentyFourHourCandle,
  ApiTwentyFourHourCandleMessage,
  MarketDataService,
  SubscriptionAction,
} from './market-data.service';
import { MarketService } from './market.service';
import { withoutResetNotifications } from './retryable.websocket';

@Injectable({
  providedIn: 'root',
})
export class MarketValueService {
  private readonly marketDataCache = new ObservableStore<string, ApiTwentyFourHourCandle>();
  // While this can get out of date, the server will try and send the latest known details as soon after subscription as
  // possible so this is mainly to avoid a brief spinner
  private readonly lastKnownSummary = new Store<string, Subject<ApiTwentyFourHourCandle>>();

  constructor(private readonly marketService: MarketService, private readonly marketDataService: MarketDataService) {}

  /**
   * Continuously updated market value for a given instrument
   */
  public getAlwaysCurrentMarketValue(instrument: Instrument): Observable<CurrentMarketValue> {
    const $candles = this.subscribe(instrument.id);
    const $cmv = merge(
      $candles.pipe(
        map((data) => {
          const market = new CurrentMarketValue(instrument);
          market.processPriceInfo(data);
          return market;
        })
      ),
      // apply default after 0.5s of missing data
      of(new CurrentMarketValue(instrument)).pipe(delay(500), takeUntil($candles))
    );

    if (instrument.termCurrency.id === Currency.USD) {
      return $cmv;
    }

    // determine conversion to USD
    const $usdEquivalentInstrument = this.marketService.findInstrument(instrument.baseCurrency.id + '/' + Currency.USD, true);
    const conversionSymbol = instrument.termCurrency.id + '/' + Currency.USD;
    const $termToUsdMarketValue = this.subscribe(conversionSymbol).pipe(
      map((data) => {
        const market = new SimpleCurrentMarketValue(data[0]);
        market.processPriceInfo(data);
        return market;
      })
    );
    return combineLatest([
      $cmv,
      merge(
        $termToUsdMarketValue,
        // apply default after 0.5s of missing data
        of(new SimpleCurrentMarketValue(conversionSymbol)).pipe(delay(500), takeUntil($termToUsdMarketValue))
      ),
      $usdEquivalentInstrument,
    ]).pipe(
      map(([cmv, termToUsdMarketValue, usdEquivalentInstrument]) => {
        cmv.termToUsdMarketValue = termToUsdMarketValue;
        cmv.usdEquivalentInstrument = usdEquivalentInstrument;
        return cmv;
      })
    );
  }

  // TODO this can probably be simplified as CurrentMarketValue only uses the instrument for sorting based on the
  // currencies, which are encoded into the symbol passed in here
  public getCurrentMarketValueForSymbol(symbol: string, allowPrivate = false): Promise<CurrentMarketValue> {
    return firstValueFrom(
      this.marketService.findInstrument(symbol, allowPrivate).pipe(
        // obtain the latest data...
        switchMap((instrument) => this.getAlwaysCurrentMarketValue(instrument))
      )
    );
  }

  private subscribe(symbol: string): Observable<ApiTwentyFourHourCandle> {
    const lastKnown = this.lastKnownSummary.get(symbol, () => new ReplaySubject(1));
    return merge(
      lastKnown.pipe(first()),
      this.marketDataCache.get(symbol, () =>
        this.marketDataService
          .subscribe(makeMessage(symbol), (msg): msg is ApiTwentyFourHourCandleMessage => isMarketSummary(msg) && msg.ms[0] === symbol)
          .pipe(
            filter(withoutResetNotifications),
            map((msg) => msg.ms),
            // can't make the subject part of this flow as that would constrain the lifetimes so instead this has to be done out-of-band
            tap((msg) => lastKnown.next(msg))
          )
      )
    );
  }
}

const makeMessage = (symbol: string) => (action: SubscriptionAction) => ({
  MarketSummaryRequest: {
    action,
    symbol,
    asBinary: true,
  },
});

const isMarketSummary = (msg: ApiMarketDataMessage): msg is ApiTwentyFourHourCandleMessage => 'ms' in msg;
