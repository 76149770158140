<form
  #externalRedirect
  *ngIf="token"
  name="external"
  action="/api/v1/private/api-auth/external-redirect/{{ partner }}"
  method="post"
  [target]="target"
  class="d-none"
>
  <input name="token" [value]="token" />
</form>
