import { Injectable } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';

import { OrderExecutionNotification, ResourceResponse, LoginSuccess, AuthService } from 'core';
import { Order } from 'entities/order';
import { DebitCard } from './spendwallet/spend.wallet.service';

export enum Theme {
  night = 'themeNight',
  day = 'themday',
}

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  logoutSource$ = this.authService.$loggedIn;

  private readonly enableAccountSource = new Subject<void>();
  readonly enableAccountSource$ = this.enableAccountSource.asObservable();

  private readonly themeChangeSource = new ReplaySubject<Theme>(1);
  readonly themeChangeSource$ = this.themeChangeSource.asObservable();

  private readonly orderChangeSource = new Subject<OrderExecutionNotification>();
  readonly orderChangeSource$ = this.orderChangeSource.asObservable();

  private readonly loginAfterVerificationSource = new ReplaySubject<ResourceResponse<LoginSuccess>>();
  readonly loginAfterVerificationSource$ = this.loginAfterVerificationSource.asObservable();

  // TODO refresh UI when KYC status changes using authService.reloadUserData?
  private readonly kycChangeSource = new Subject<void>();
  readonly kycChangeSource$ = this.kycChangeSource.asObservable();

  private readonly accountBlockedSource = new Subject<void>();
  readonly accountBlockedSource$ = this.accountBlockedSource.asObservable();

  private readonly closedOrderSource = new Subject<Order>();
  readonly $closedOrders = this.closedOrderSource.asObservable();

  private readonly cardCreatedSource = new Subject<DebitCard>();
  readonly $cardCreated = this.cardCreatedSource.asObservable();

  private readonly cardOrderedSource = new Subject<DebitCard>();
  readonly $cardOrdered = this.cardOrderedSource.asObservable();

  constructor(private readonly authService: AuthService) {}

  broadcastOrderChange(event: OrderExecutionNotification): void {
    this.orderChangeSource.next(event);
  }

  broadcastLogoutChange(loggedIn: boolean): void {
    this.authService.publishLoggedInStatus(loggedIn);
  }

  broadcastEnableAccountChange(): void {
    this.enableAccountSource.next();
  }

  broadcastThemeChange(theme: Theme) {
    this.themeChangeSource.next(theme);
  }

  broadcastLoginAfterVerification(loginResponse: ResourceResponse<LoginSuccess>) {
    this.loginAfterVerificationSource.next(loginResponse);
  }

  broadcastKycStatusChange() {
    this.kycChangeSource.next();
  }

  broadcastAccountBlockedChange() {
    this.accountBlockedSource.next();
  }

  closedOrder(order: Order) {
    this.closedOrderSource.next(order);
  }

  cardCreated(card: DebitCard) {
    this.cardCreatedSource.next(card);
  }

  cardOrdered(card: DebitCard) {
    this.cardOrderedSource.next(card);
  }
}
