import { Never, Unknown } from 'runtypes';

/**
 * Runtypes validation of the specified enum.
 *
 * @param target The enumeration to validate
 */
export const Enum = <E>(target: Record<string, E>) => {
  const elements = Object.values<unknown>(target);
  return Unknown.withGuard((d): d is E => elements.includes(d), { name: 'Enum' });
};

/** A validation which is only successful when the disallowed value is not provided */
export const Missing = Never.optional();

/** Prove that a value is not nullish */
export const defined = <T>(v: T): v is NonNullable<T> => v !== null && v !== undefined;

// Useful, but not built-in, type function to pull out just properties of a particular type
export type KeyOf<T, P> = keyof Pick<T, { [K in keyof T]: T[K] extends P ? K : never }[keyof T]>;
