import { SafeUrl } from '@angular/platform-browser';

export class WalletAddress {
  constructor(
    readonly address: string | null,
    readonly legacyAddress: string | null,
    readonly qrCodeUrl?: SafeUrl,
    readonly qrCodeUrlAddress?: SafeUrl,
    readonly qrCodeUrlId?: SafeUrl,
    readonly qrCodeLegacyUrl?: SafeUrl
  ) {}

  get displayAddress(): string {
    if (this.address && this.address.length > 0) {
      return this.address;
    } else {
      return 'Wallet address is being created, check in a few minutes.';
    }
  }

  get displayWalletAddress(): string | undefined {
    return this.address?.split('?')[0];
  }

  get displayWalletID(): string | undefined {
    const wallet = this.address?.split('?')[1];
    return wallet?.split('=')[1];
  }
}
