import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import BigNumber from 'bignumber.js';
import * as R from 'runtypes';

import {
  RetryableWebSocket,
  OrderExecution,
  OrderExecutionNotification,
  OrderStatus,
  OrderChangeResponse,
  AuthService,
  withoutResetNotifications,
  Enum,
  defined,
} from 'core';
import { URLS } from 'commons/app.constant';
import { BroadcastService } from 'services/broadcast.service';
import { MessageService } from '../message/message.service';
import { CardProcessingStatus, DebitCard } from 'services/spendwallet/spend.wallet.service';

const TOO_MANY_CONNECTIONS = 4001;
const TOKEN_EXPIRED = 4002;

type Payload = {
  type: string;
  [key: string]: unknown;
};

type Sub = {
  token: string;
};

const CardCreationEvent = R.Record({
  card: DebitCard,
});

const DebitCardOrderMessage = R.Record({
  status: Enum(CardProcessingStatus),
  card: DebitCard.nullable(),
  error: R.String.nullable(),
});

@Injectable({
  providedIn: 'root',
})
export class NotificationSocketService {
  private websocket: RetryableWebSocket<Payload, Sub>;
  private subscription: Subscription;

  constructor(
    private readonly authService: AuthService,
    private readonly broadcastService: BroadcastService,
    private readonly messageService: MessageService
  ) {}

  connectWebSocket(): void {
    this.websocket = new RetryableWebSocket(
      URLS.orderNotificationSocket,
      () => this.sendSubscription(),
      (code?: number) => {
        if (TOKEN_EXPIRED === code) {
          // Force token refresh
          this.authService.setToken(null);
        }
        return TOO_MANY_CONNECTIONS !== code && this.authService.loggedIn;
      }
    );

    this.subscription = this.websocket.observe().pipe(filter(withoutResetNotifications)).subscribe(this.onMessage.bind(this));
  }

  disconnectWebSocket(): void {
    this.subscription.unsubscribe();
  }

  private sendSubscription(): void {
    this.authService.getToken().then((token) => {
      if (this.websocket) {
        this.websocket.send({ token });
      }
    }, console.log);
  }

  private onMessage(responseData: Payload): void {
    if (responseData.type == 'Order') {
      this.processOrderUpdate(responseData);
    } else if (responseData.type == 'Kyc') {
      this.processKycUpdate();
    } else if (responseData.type == 'Deposit' || responseData.type == 'Withdrawal' || responseData.type == 'Transfer') {
      this.processLedgerUpdate(responseData);
    } else if (responseData.type == 'ReferrerReward' || responseData.type == 'ReferreeReward') {
      this.processReferralReward(responseData);
    } else if (responseData.type == 'ACHDepositReversal') {
      this.processAchDepositReversal(responseData);
    } else if (responseData.type == 'CryptoWithdrawalFailure') {
      this.processCryptoWithdrawalFailure(responseData);
    } else if (responseData.type == 'RequestedPaymentPriceChange') {
      this.processRequestedPaymentPriceChange(responseData);
    } else if (responseData.type == 'MarginWarning') {
      this.processMarginWarning(responseData);
    } else if (responseData.type == 'MarginCall') {
      this.processMarginCall(responseData);
    } else if (responseData.type == 'CardCreation') {
      this.processCardCreation(responseData);
    } else if (responseData.type == 'CardUpdate') {
      this.processCardUpdate(responseData);
    } else if (responseData.type == 'CardOrder') {
      this.processCardOrder(responseData);
    }
  }

  private processMarginWarning(responseData: any): void {
    const messageText = responseData.displayMessage;
    if (messageText) {
      this.messageService.warning(messageText);
    }
  }

  private processMarginCall(responseData: any): void {
    const messageText = responseData.displayMessage;
    if (messageText) {
      this.messageService.error(messageText);
    }
  }

  private processRequestedPaymentPriceChange(responseData: any): void {
    const messageText = responseData.displayMessage;
    if (messageText) {
      this.messageService.success(messageText);
    }
  }

  private processCryptoWithdrawalFailure(responseData: any) {
    const messageText = responseData.displayMessage ? responseData.displayMessage : responseData.message.message;
    this.messageService.error(messageText);
  }

  private processAchDepositReversal(responseData: any): void {
    const messageText = responseData.displayMessage ? responseData.displayMessage : responseData.message.message;
    this.messageService.error(messageText);
  }

  private processKycUpdate(): void {
    this.broadcastService.broadcastKycStatusChange();
  }

  private processLedgerUpdate(responseData: any): void {
    const messageText = responseData.displayMessage ? responseData.displayMessage : responseData.message.message;

    if (responseData.success === false) {
      this.messageService.error(messageText);
    } else {
      this.messageService.success(messageText);
    }
  }

  private processReferralReward(responseData: any): void {
    const messageText = responseData.displayMessage ? responseData.displayMessage : responseData.message.message;
    this.messageService.success(messageText);
  }

  private processOrderUpdate(responseData: any): void {
    const messageText = responseData.displayMessage ? responseData.displayMessage : responseData.message.message;
    if (responseData.data) {
      if (responseData.data.orderStatusEn === OrderStatus.Rejected) {
        this.messageService.error(messageText);
      } else {
        this.messageService.success(messageText);
      }
    } else {
      if (responseData.success) {
        this.messageService.success(messageText);
      } else {
        this.messageService.error(messageText);
      }
    }
    this.broadcastService.broadcastOrderChange(fromOrderChange(responseData.data));
  }

  private processCardCreation(responseData: any): void {
    const messageText = `Your virtual debit card has been created`;
    this.messageService.success(messageText);

    this.broadcastService.cardCreated(CardCreationEvent.check(responseData.data).card);
  }

  private processCardUpdate(responseData: any): void {
    const messageText = `Congratulations, your ZOOM balance has upgraded your card to ${responseData.data.level}`;
    this.messageService.success(messageText);
  }

  private processCardOrder(responseData: any): void {
    const event = DebitCardOrderMessage.check(responseData.data);
    if (event.status === CardProcessingStatus.COMPLETE && defined(event.card)) {
      const msg = `Your debit card has been ordered`;
      this.messageService.success(msg);
      this.broadcastService.cardOrdered(event.card);
    } else {
      const msg = event.error ?? '';
      this.messageService.error(msg);
    }
  }
}

const fromOrderChange = (rawData: OrderChangeResponse): OrderExecutionNotification => ({
  execution: new OrderExecution(
    rawData.orderId,
    rawData.ocoOrderId,
    rawData.transactTimeTs,
    rawData.orderSideEn,
    new BigNumber(rawData.lastQuantityAmt),
    new BigNumber(rawData.cumulativeQuantityAmt),
    rawData.averagePriceRt,
    rawData.lastPriceRt,
    rawData.orderStatusEn,
    rawData.rejectReason,
    new BigNumber(rawData.leavesQuantityAmt)
  ),
  orderTypeEn: rawData.orderTypeEn,
  symbolCd: rawData.symbolCd,
  priceRt: rawData.priceRt,
  stopPriceRt: rawData.stopPriceRt,
  clientOrderId: rawData.clientOrderId,
  leverage: rawData.leverage,
  closingPositionFg: rawData.closingPositionFg,
  note: rawData.note,
});
