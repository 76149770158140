import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { distinctUntilKeyChanged } from 'rxjs/operators';

import { Instrument } from '../entities/instrument';

@Injectable({
  providedIn: 'root',
})
export class SelectedInstrumentService {
  private selectedInstrument = new ReplaySubject<Instrument>(1);
  $selectedInstrument = this.selectedInstrument.asObservable().pipe(distinctUntilKeyChanged('id'));

  private displayedInstruments = new ReplaySubject<Array<Instrument>>(1);
  $displayedInstruments = this.displayedInstruments.asObservable();

  selectInstrument(instrument: Instrument): void {
    this.selectedInstrument.next(instrument);
  }

  displayInstruments(instruments: Array<Instrument>): void {
    this.displayedInstruments.next(instruments);
  }
}
