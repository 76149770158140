import { Observable } from 'rxjs';
import { finalize, share } from 'rxjs/operators';

/**
 * Provides values for keys which can be converted to strings. Key conversion defaults to `String` conversion.
 */
export class Store<K, V> {
  private readonly store = new Map<string, V>();

  constructor(private readonly flatten: (key: K) => string = String) {}

  /**
   * Provides the stored value or, if there is none, populates it from the given creation function.
   */
  get(key: K, creator: (key: K) => V): V {
    const storekey = this.flatten(key);
    let cached = this.store.get(storekey);

    if (cached === undefined) {
      cached = creator(key);
      this.store.set(storekey, cached);
    }

    return cached;
  }

  delete(key: K): void {
    this.store.delete(this.flatten(key));
  }
}

/**
 * A store where an entry only lasts as long as the value has consumers.
 */
export class ObservableStore<K, V> {
  private readonly store: Store<K, Observable<V>>;

  constructor(flatten: (key: K) => string = String) {
    this.store = new Store(flatten);
  }

  /**
   * Provides the stored value or, if there is none, populates it from the given creation function.
   */
  get(key: K, creator: (key: K) => Observable<V>): Observable<V> {
    return this.store.get(key, () =>
      creator(key).pipe(
        finalize(() => this.store.delete(key)),
        share()
      )
    );
  }
}
