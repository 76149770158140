import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { OrderSide, OrderType, TimeInForce } from '../entities/order';
import { URLS } from './app.constant';
import { AuthService } from './auth.service';
import { HttpOptions } from '../utils/httpOptions';

export class PositionClose {
  constructor(readonly positionId: string | null) {}
}

export const DEFAULT_LIMIT_PRICE_COLOUR = '#d39a33';
export const DEFAULT_STOP_PRICE_COLOUR = '#7eb2ff';
export const DEFAULT_TAKE_PROFIT_COLOUR = '#60bb46';
export const DEFAULT_STOP_LOSS_COLOUR = 'red';
export const DEFAULT_MARKET_COLOUR = '#6fff92';
export const DEFAULT_OPEN_COLOUR = '#d3c333';

export type TradeDefaults = {
  tradeFromChart: boolean;
  feesInZoom: boolean;
  enableTpSlOnNewPositions: boolean;
  enableMarginOnNewTrades: boolean;
  orderType: OrderType;
  marketPriceColour: string;
  limitPriceColour: string;
  stopPriceColour: string;
  openPriceColour: string;
  takeProfitColour: string;
  stopLossColour: string;
};

export const DEFAULT_TRADE_DEFAULTS = {
  tradeFromChart: false,
  feesInZoom: false,
  enableTpSlOnNewPositions: false,
  enableMarginOnNewTrades: false,
  orderType: OrderType.Market,
  marketPriceColour: DEFAULT_MARKET_COLOUR,
  limitPriceColour: DEFAULT_LIMIT_PRICE_COLOUR,
  stopPriceColour: DEFAULT_STOP_PRICE_COLOUR,
  openPriceColour: DEFAULT_OPEN_COLOUR,
  takeProfitColour: DEFAULT_TAKE_PROFIT_COLOUR,
  stopLossColour: DEFAULT_STOP_LOSS_COLOUR,
};

@Injectable({
  providedIn: 'root',
})
export class TradeService {
  readonly tradeDefaultsSource$ = new BehaviorSubject<TradeDefaults>(DEFAULT_TRADE_DEFAULTS);

  constructor(private readonly http: HttpClient, private readonly authService: AuthService) {
    if (this.authService.loggedIn) {
      this.loadTradeDefaults();
    }

    this.authService.$loggedIn.subscribe({
      next: (loggedIn) => {
        if (loggedIn) {
          this.loadTradeDefaults();
        } else {
          this.tradeDefaultsSource$.next(DEFAULT_TRADE_DEFAULTS);
        }
      },
    });
  }

  public placeOrder(
    feesInZoom: boolean,
    price: number | null,
    stopPrice: number | null,
    amount: number,
    instrumentId: string,
    orderSide: OrderSide,
    orderType: OrderType,
    note: string | null,
    leverage: number | null,
    positionClose: PositionClose | null,
    takeProfitPrice: number | null,
    stopLossPrice: number | null,
    timeInForce?: TimeInForce | null,
    otp?: number | null
  ): Observable<string> {
    return this.http.post<string>(
      URLS.placeOrder,
      {
        symbolCd: instrumentId,
        orderTypeEn: orderType,
        orderSideEn: orderSide,
        priceRt: price,
        stopPriceRt: orderType === OrderType.StopLimit ? stopPrice : null,
        quantityAmt: amount,
        note,
        leverage,
        positionClose,
        takeProfitPriceRt: takeProfitPrice,
        stopLossPriceRt: stopLossPrice,
        deductZoomToken: feesInZoom,
        timeInForce,
      },
      HttpOptions.builder().withOTP(otp).build()
    );
  }

  public placeOCO(
    feesInZoom: boolean,
    takeProfitPrice: number,
    stopLossPrice: number,
    amount: number,
    instrumentId: string,
    orderSide: OrderSide,
    note: string | null,
    timeInForce?: TimeInForce | null,
    otp?: number | null
  ): Observable<Array<string>> {
    return this.http.post<Array<string>>(URLS.placeOCO, {
      symbolCd: instrumentId,
      orderSideEn: orderSide,
      takeProfitPriceRt: takeProfitPrice,
      stopLossPriceRt: stopLossPrice,
      quantityAmt: amount,
      note,
      otp,
      deductZoomToken: feesInZoom,
      timeInForce,
    });
  }

  public saveTradeDefaults(defaults: TradeDefaults): Observable<void> {
    return this.http.post<void>(URLS.tradeDefaults, defaults).pipe(
      tap((_) => {
        this.tradeDefaultsSource$.next(defaults);
      })
    );
  }

  private loadTradeDefaults(): void {
    this.http.get<TradeDefaults>(URLS.tradeDefaults).subscribe({
      next: (defaults) => this.tradeDefaultsSource$.next(defaults),
      error: (error) => console.log(error),
    });
  }
}
