import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MultiplexedWebSocket } from './multiplexed-websocket.service';
import { URLS } from './app.constant';
import { OrderSide } from '../entities/order';
import { RESET } from './retryable.websocket';

// All streaming messages
export type ApiMarketDataMessage =
  | ApiIncrementalOrderBook
  | ApiFullOrderBook
  | ApiTwentyFourHourCandleMessage
  | ApiCurrentCandle
  | ApiTradeHistoryMessage
  | ApiSystemNotificationMessage;

// --------------------------------------------------
// order book
export type ApiIncrementalOrderBook = {
  oi: string;
  b: Array<BookedOrder | OrderCancel>;
  s: Array<BookedOrder | OrderCancel>;
};
export type ApiFullOrderBook = {
  ob: string;
  b: Array<BookedOrder>;
  s: Array<BookedOrder>;
};
export type BookedOrder = [
  string, // ID
  number, // price
  number // amount
];
export type OrderCancel = [
  string // ID
];

// --------------------------------------------------
// 24 hour candles
export type ApiTwentyFourHourCandleMessage = {
  ms: ApiTwentyFourHourCandle;
};
export type ApiTwentyFourHourCandle = [
  string, // symbol
  number, // open
  number, // high
  number, // low
  number, // close
  number, // volume
  number, // best bid
  number // best ask
];

// --------------------------------------------------
// candles
export type ApiCurrentCandle = {
  cu: string; // 'BTC/USD'
  i: string; // 'M1'
  c: Array<ApiCandle>;
};
export type ApiCandle = [
  string, // date
  number, // open
  number, // low
  number, // high
  number, // close
  number // volume
];

// --------------------------------------------------
// trade history
export type ApiTradeHistoryMessage = {
  ts: ApiTradeHistory;
};
export type ApiTradeHistory = [
  string, // symbol
  number, // price
  number, // amount
  string, // time
  OrderSide // side
];

// --------------------------------------------------
// system notifications
export type ApiSystemNotificationMessage = {
  SystemNotificationEvent: SystemNotificationDTO;
};
export type SystemNotificationDTO = {
  id: number;
  notificationText: string;
  visibleToTs: string; // in ISO-8601
};

// --------------------------------------------------
export type SubscriptionAction = 'subscribe' | 'unsubscribe';

@Injectable({
  providedIn: 'root',
})
export class MarketDataService {
  private websocket: MultiplexedWebSocket<ApiMarketDataMessage, unknown>;

  constructor() {
    this.websocket = new MultiplexedWebSocket(URLS.marketDataStreamUrl);
  }

  subscribe<T extends ApiMarketDataMessage>(
    subscriptionDetails: (action: SubscriptionAction) => unknown,
    messageFilter: (msg: ApiMarketDataMessage) => msg is T
  ): Observable<T | RESET> {
    return this.websocket.subscribe(subscriptionDetails('subscribe'), subscriptionDetails('unsubscribe'), messageFilter);
  }
}
