import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private readonly sanitiser: DomSanitizer) {}

  makeImageUrl(raw?: string | null): SafeUrl {
    return this.sanitiser.bypassSecurityTrustUrl('data:image/png;base64,' + raw);
  }
}
