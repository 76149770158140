import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { Utils, SettingsService } from 'core';
import { MessageService } from 'services/message/message.service';

@Component({
  selector: 'app-ssn-modal',
  templateUrl: './ssn-modal.component.html',
  styleUrls: ['./ssn-modal.component.scss'],
})
export class SsnModalComponent {
  // export to template
  onlyNumericAllowed = Utils.onlyNumericAllowed;

  model = {
    ssn: '',
  };

  private done = new Subject<boolean>();
  $done = this.done.asObservable();

  constructor(
    private readonly modal: BsModalRef,
    private readonly kycService: SettingsService,
    private readonly messageService: MessageService
  ) {}

  close(success = false) {
    this.modal.hide();
    this.done.next(success);
    this.done.complete();
  }

  submit() {
    this.kycService.submitSsn(this.model.ssn).subscribe((resp) => {
      if (resp.isSuccess) {
        this.messageService.success(resp.message);
      } else {
        this.messageService.warning(resp.message);
      }
      this.close(resp.isSuccess);
    });
  }
}
