import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, from, EMPTY, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { URLS } from './services/app.constant';
import { AuthService } from './services/auth.service';
import { MessageService } from './services/message.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedHttpService implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly messageService: MessageService,
    private readonly authService: AuthService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.authService.loggedIn && req.url != URLS.refreshToken) {
      return from(this.authService.getToken()).pipe(
        switchMap((token) => next.handle(req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) }))),
        catchError((err) => {
          if (err instanceof HttpErrorResponse && err.status == 401) {
            this.messageService.sessionExpire(err);
            return EMPTY;
          } else if (err.status == 0) {
            this.authService.clearAuthData();
            this.router.navigate(['']);
            return EMPTY;
          } else {
            return throwError(() => err);
          }
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
