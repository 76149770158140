import { Component, Input, OnChanges } from '@angular/core';

import { Instrument } from '../../../entities/instrument';
import { trailingZeros } from '../../../utils/number';

@Component({
  selector: 'cz-instrument-price',
  templateUrl: './instrument-price.component.html',
  styleUrls: ['./instrument-price.component.scss'],
})
export class InstrumentPriceComponent implements OnChanges {
  @Input() price?: number | null;
  @Input() instrument: Instrument;
  @Input() highlightTrailingZeros = true;

  displayedPrice: string;
  trailingZeros: string;

  ngOnChanges(): void {
    if (this.instrument) {
      const price = this.instrument.displayPrice(this.price);
      if (this.highlightTrailingZeros) {
        const display = trailingZeros(price);
        this.displayedPrice = display.num;
        this.trailingZeros = display.zeros;
      } else {
        this.displayedPrice = price;
        this.trailingZeros = '';
      }
    }
  }
}
