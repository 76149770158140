import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import * as R from 'runtypes';

import { WalletAddress } from '../entities/wallet.address';
import { URLS } from './app.constant';
import { defined } from '../utils/runtypes';
import { ImageService } from './image.service';

const ApiWalletAddress = R.Record({
  address: R.String.nullable(),
  legacyAddress: R.String.nullable(),
  base64QrImage: R.String.nullable(),
  base64QrImagePart1: R.String.nullable(),
  base64QrImagePart2: R.String.nullable(),
  base64LegacyQrImage: R.String.nullable(),
});
export type ApiWalletAddress = R.Static<typeof ApiWalletAddress>;

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  constructor(private readonly http: HttpClient, private readonly imageService: ImageService) {}

  getWalletAddresses(symbol: string, network?: string, paymentId?: string): Observable<WalletAddress[]> {
    let url = URLS.getWalletAddressUrl.replace('{ccy}', symbol);
    if (paymentId) {
      url += '/' + paymentId;
    }
    if (network) {
      url += `?network=${network}`;
    }
    return this.http.get(url).pipe(
      map(R.Array(ApiWalletAddress).check),
      map((addresses) => addresses.map(this.buildAddress.bind(this)))
    );
  }

  createWalletAddress(currencySymbol: string, network?: string, paymentId?: string): Observable<WalletAddress> {
    let url = URLS.createWalletAddressUrl.replace('{ccy}', currencySymbol);
    if (paymentId) {
      url += '/' + paymentId;
    }
    if (network) {
      url += `?network=${network}`;
    }
    return this.http.get(url).pipe(map(ApiWalletAddress.check), map(this.buildAddress.bind(this)));
  }

  private buildAddress(raw: ApiWalletAddress) {
    let qrCodeUrl, qrCodeUrlAddress, qrCodeUrlId, qrCodeLegacyUrl;
    if (defined(raw.address) && raw.address.length > 0) {
      qrCodeUrl = this.imageService.makeImageUrl(raw.base64QrImage);
      if (raw.base64QrImagePart1 && raw.base64QrImagePart2) {
        qrCodeUrlAddress = this.imageService.makeImageUrl(raw.base64QrImagePart1);
        qrCodeUrlId = this.imageService.makeImageUrl(raw.base64QrImagePart2);
      }
      if (raw.base64LegacyQrImage) {
        qrCodeLegacyUrl = this.imageService.makeImageUrl(raw.base64LegacyQrImage);
      }
    }

    return new WalletAddress(raw.address, raw.legacyAddress, qrCodeUrl, qrCodeUrlAddress, qrCodeUrlId, qrCodeLegacyUrl);
  }
}
