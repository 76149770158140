import { Injectable } from '@angular/core';
import { defer, iif, map, mergeMap, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as R from 'runtypes';
import { $challenge, $dataCollection, ChallengeResponse } from './songbird';
import { URLS } from './app.constant';
import { defined, Enum } from '../utils/runtypes';
import { ApiGatewayLimit } from '../entities/gateway.limit';
import { Utils } from '../utils/utils';
import { ApiConfirmPaymentResponse, ConfirmationStatus, ConfirmPaymentResponse, PaymentSummary } from '../entities/payments';
import { Currency } from '../entities/currency';
import { FeeTypes } from '../entities/fees';
import { HttpOptions } from '../utils/httpOptions';

export enum LookupStatus {
  DEPOSIT_COMPLETED = 'DEPOSIT_COMPLETED',
  CHALLENGE = 'CHALLENGE',
}

export const ApiBankCard = R.Record({
  binNumber: R.String,
  last4Digits: R.String,
  maskedCard: R.String,
  expiryDate: R.String,
  needsUpdate: R.Boolean,
  hasSuccessful3dsSession: R.Boolean,
}).asReadonly();

export const ApiInit3DSResponse = R.Record({
  sessionId: R.String.nullable(),
  jwt: R.String.nullable(),
}).asReadonly();
export const ApiLookup3DSResponse = R.Record({
  sessionId: R.String.nullable(),
  status: Enum(LookupStatus),
  challengeURL: R.String.nullable(),
  processorTransactionID: R.String.nullable(),
  challengePayload: R.String.nullable(),
  holdHour: R.Number.nullable(),
}).asReadonly();
export const ApiAuthenticate3DSResponse = R.Record({
  holdHour: R.Number.nullable(),
}).asReadonly();

export const ApiBankCardControlData = R.Record({
  depositLimit: ApiGatewayLimit,
  withdrawalLimit: ApiGatewayLimit,
  lockPeriod: R.Number,
  maxLockPeriod: R.Number,
  iframeURL: R.String,
}).asReadonly();

export const ApiInitPayment3DSResponse = R.Record({
  status: Enum(ConfirmationStatus),
  confirmedPaymentCost: R.Number.nullable(),
  threeDSResponse: ApiInit3DSResponse.nullable(),
}).asReadonly();

export const ApiLookupPayment3DSResponse = R.Record({
  status: Enum(ConfirmationStatus),
  confirmedPaymentCost: R.Number.nullable(),
  threeDSResponse: ApiLookup3DSResponse.nullable(),
}).asReadonly();

export type Init3DSResponse = R.Static<typeof ApiInit3DSResponse>;
export type Lookup3DSResponse = R.Static<typeof ApiLookup3DSResponse>;
export type InitPayment3DSResponse = R.Static<typeof ApiInitPayment3DSResponse>;
export type LookupPayment3DSResponse = R.Static<typeof ApiLookupPayment3DSResponse>;
export type BankCardControlData = R.Static<typeof ApiBankCardControlData>;
export type BankCard = R.Static<typeof ApiBankCard>;

export type Deposit3DSFinalResult = {
  success: boolean;
  holdHours: number | null;
};

export type AuthPaymentResponse = {
  threeDSCompleted: boolean;
  paymentConfirmation: ConfirmPaymentResponse | null;
};

@Injectable({
  providedIn: 'root',
})
export class ThreeDSecureService {
  private static SONGBIRD_URL = '/scripts/3ds/songbird.js';

  constructor(private readonly http: HttpClient) {}

  getBankCardControlMetadata(): Observable<BankCardControlData> {
    return this.http.get(URLS.bankCardMetadataUrl).pipe(map(ApiBankCardControlData.check));
  }

  getSavedCards(): Observable<Array<BankCard>> {
    return this.http.get(URLS.bankCardListUrl).pipe(map(R.Array(ApiBankCard).check));
  }

  deleteCard(card: BankCard): Observable<void> {
    return this.http.delete<void>(URLS.bankCardDeleteUrl(card.binNumber, card.last4Digits));
  }

  /**
   * The 3DS data capture and challenge flows are performed by a thirdparty library called songbird.js
   * This library doesn't appear to work very well in a single page application i.e. it works the first
   * time but not if another deposit is attempted without refreshing the page to reload the library. To
   * work around this we dynamically load and unload the library each time it is needed.
   */
  loadSongBird(): Observable<void> {
    // Remove any loaded copy in case it wasn't cleaned up properly the last time.
    this.unLoadSongBird();

    // Load the library.
    return new Observable((subcribe) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = ThreeDSecureService.SONGBIRD_URL;

      script.onload = () => {
        subcribe.next();
        subcribe.complete();
      };
      script.onerror = (error: unknown) => subcribe.error(error);

      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }

  /**
   * We explicitly load SONGBIRD_URL but this is a bootstrap library that loads other javascript
   * files. To unlooad all the songbird related code we look for anything with the word songbird in
   * the URL. I know this is fragile but I'm not sure there's another option.
   */
  unLoadSongBird(): void {
    document.querySelectorAll(`head script[src*=songbird]`).forEach((e) => e.remove());
  }

  init3DS(
    mode: string,
    card: BankCard,
    token: string | null,
    amount: number,
    note: string | null,
    cvv?: string | null
  ): Observable<Init3DSResponse> {
    return this.http
      .post(URLS.bankCardInit3DSUrl, {
        mode,
        binNumber: card.binNumber,
        last4Digits: card.last4Digits,
        expiryDate: card.expiryDate,
        token,
        amount,
        note,
        cvv: defined(cvv) ? cvv : null,
      })
      .pipe(map(ApiInit3DSResponse.check));
  }

  initPayment3DS(
    targetedPayment: boolean,
    transactionId: string,
    payment: PaymentSummary,
    mode: string,
    card: BankCard,
    token: string | null,
    note: string | null,
    cvv?: string | null
  ): Observable<InitPayment3DSResponse> {
    return this.http
      .post(targetedPayment ? URLS.bankCardInitPayment3DSUrl + '/targeted' : URLS.bankCardInitPayment3DSUrl, {
        transactionId,
        paymentId: payment.paymentId,
        partnerPaidCurrencyCd: payment.currencyCd,
        customerPaidCurrencyCd: Currency.USD,
        version: payment.version,
        note,
        web: true,
        mode,
        binNumber: card.binNumber,
        last4Digits: card.last4Digits,
        expiryDate: card.expiryDate,
        token,
        cvv,
      })
      .pipe(map(ApiInitPayment3DSResponse.check));
  }

  lookupPayment3DS(
    targetedPayment: boolean,
    transactionId: string,
    payment: PaymentSummary,
    response: Init3DSResponse
  ): Observable<LookupPayment3DSResponse> {
    return this.http
      .post(targetedPayment ? URLS.bankCardLookupPayment3DSUrl + '/targeted' : URLS.bankCardLookupPayment3DSUrl, {
        transactionId,
        paymentId: payment.paymentId,
        partnerPaidCurrencyCd: payment.currencyCd,
        customerPaidCurrencyCd: Currency.USD,
        version: payment.version,
        web: true,
        sessionId: response.sessionId,
        colorDepth: `${screen.colorDepth}`,
        screenWidth: `${screen.width}`,
        screenHeight: `${screen.height}`,
        language: navigator.language,
      })
      .pipe(map(ApiLookupPayment3DSResponse.check));
  }

  /**
   * Does the browser challenge. If that succeeds calls the server side authenticate method to validate this and
   * complete the transaction. The browser challenge could fail (e.g. the user cancels). In that case we abort and
   * do no server calls.
   */
  challengePayment(
    targetedPayment: boolean,
    transactionId: string,
    payment: PaymentSummary,
    response: Lookup3DSResponse
  ): Observable<AuthPaymentResponse> {
    return this.doBrowserChallenge(response).pipe(
      mergeMap((result) =>
        defer(() =>
          result.success
            ? this.http
                .post(targetedPayment ? URLS.bankCardAuthenticatePayment3DSUrl + '/targeted' : URLS.bankCardAuthenticatePayment3DSUrl, {
                  transactionId,
                  paymentId: payment.paymentId,
                  partnerPaidCurrencyCd: payment.currencyCd,
                  customerPaidCurrencyCd: Currency.USD,
                  version: payment.version,
                  sessionId: response.sessionId,
                  jwt: result.jwt,
                })
                .pipe(
                  map(ApiConfirmPaymentResponse.check),
                  map((r) => {
                    return { threeDSCompleted: true, paymentConfirmation: r };
                  })
                )
            : of({ threeDSCompleted: false, paymentConfirmation: null })
        )
      )
    );
  }

  /**
   * Perform the 3DS data collection. See https://developers.tabapay.com/reference/device-data-collection.
   */
  collect3DSData(response: Init3DSResponse): Observable<Init3DSResponse> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return $dataCollection(response.jwt!).pipe(map(() => response));
  }

  lookup3DS(response: Init3DSResponse): Observable<Lookup3DSResponse> {
    return this.http
      .post(URLS.bankCardLookup3DSUrl, {
        sessionId: response.sessionId,
        colorDepth: `${screen.colorDepth}`,
        screenWidth: `${screen.width}`,
        screenHeight: `${screen.height}`,
        language: navigator.language,
      })
      .pipe(map(ApiLookup3DSResponse.check));
  }

  /**
   * Does the browser challenge. If that succeeds calls the server side authenticate method to validate this and
   * complete the transaction. The browser challenge could fail (e.g. the user cancels). In that case we abort and
   * do no server calls.
   */
  challenge(response: Lookup3DSResponse): Observable<Deposit3DSFinalResult> {
    return this.doBrowserChallenge(response).pipe(
      mergeMap((result) =>
        iif<Deposit3DSFinalResult, Deposit3DSFinalResult>(
          () => result.success,
          this.http.post(URLS.bankCardAuthenticate3DSUrl, { sessionId: response.sessionId, jwt: result.jwt }).pipe(
            map(ApiAuthenticate3DSResponse.check),
            map((apiResponse) => {
              return { success: true, holdHours: apiResponse.holdHour };
            })
          ),
          of({ success: false, holdHours: null })
        )
      )
    );
  }

  withdraw(
    mode: string,
    card: BankCard,
    token: string | null,
    amount: number,
    note: string | null,
    otp: number | null,
    feePaymentMethod: FeeTypes,
    cvv?: string | null
  ): Observable<void> {
    return this.http.post<void>(
      URLS.bankCardWithdraw3DSUrl,
      {
        mode,
        binNumber: card.binNumber,
        last4Digits: card.last4Digits,
        expiryDate: card.expiryDate,
        token,
        amount,
        note,
        feePaymentMethod,
        cvv,
      },
      HttpOptions.builder().withOTP(otp).build()
    );
  }

  private doBrowserChallenge(response: Lookup3DSResponse): Observable<ChallengeResponse> {
    return $challenge(response);
  }

  public buildInfoText(metadata: BankCardControlData): string {
    let infoText = '';

    const limitParts = new Array<string>();
    if (metadata.depositLimit.maxAmountPerTxnAmt) {
      limitParts.push(`$${metadata.depositLimit.maxAmountPerTxnAmt}/transaction`);
    }
    if (metadata.depositLimit.maxAmountPerDayAmt) {
      limitParts.push(`$${metadata.depositLimit.maxAmountPerDayAmt}/day`);
    }
    if (metadata.depositLimit.maxAmountPerWeekAmt) {
      limitParts.push(`$${metadata.depositLimit.maxAmountPerWeekAmt}/week`);
    }

    if (limitParts.length > 0) {
      infoText = `Deposit limits: ${limitParts.join(', ')}. `;
    }

    infoText += 'Card name and address must match your CoinZoom profile. ';

    if (metadata.maxLockPeriod > 0) {
      if (metadata.lockPeriod > 0) {
        infoText += `Funds are instantly available for trading. Withdrawals are typically available after ${Utils.getFormattedLockPeriod(
          metadata.lockPeriod
        )}`;
        if (metadata.lockPeriod < metadata.maxLockPeriod) {
          infoText += `, or up to ${Utils.getFormattedLockPeriod(
            metadata.maxLockPeriod
          )} if your deposit cannot be fully verified with your bank.`;
        } else {
          infoText += '.';
        }
      } else {
        infoText += `Funds are instantly available for trading. Withdrawals could be held of up to for ${Utils.getFormattedLockPeriod(
          metadata.maxLockPeriod
        )} if your deposit cannot be fully verified with your bank.`;
      }
    }

    return infoText;
  }
}
