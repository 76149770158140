import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { merge } from 'rxjs';
import { faTwitter, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

import { BroadcastService } from 'services/broadcast.service';
import { AuthService } from 'core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  faTwitter = faTwitter;
  faFacebook = faFacebookF;
  faLinkedin = faLinkedinIn;

  exchangeBlocked = false;
  currentYear: number = new Date().getFullYear();

  constructor(
    private readonly router: Router,
    private readonly broadcastService: BroadcastService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.trackExchangeBlocked();

    // Don't need to clean up the subscriptions as the footer never goes away
    merge(this.broadcastService.logoutSource$, this.broadcastService.accountBlockedSource$).subscribe(() => this.trackExchangeBlocked());
  }

  private trackExchangeBlocked() {
    this.exchangeBlocked = this.authService.getLoggedInUserDetails()?.exchangeBlocked ?? false;
  }

  enableAccount(): void {
    this.router.navigateByUrl('settings');
    setTimeout(() => {
      this.broadcastService.broadcastEnableAccountChange();
    }, 100);
  }
}
