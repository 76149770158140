import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[czNumberFormat]',
})
export class NumberFormatDirective implements OnChanges {
  @Input('czNumberFormat') number: any;

  constructor(private readonly el: ElementRef) {}

  ngOnChanges(changes: { number: any }): void {
    if (changes.number && this.number) {
      const parts = this.number.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.el.nativeElement.textContent = parts.join('.');
    } else {
      this.el.nativeElement.textContent = 0;
    }
  }
}
