import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';

import { SsnModalComponent } from 'commons/ssn-modal/ssn-modal.component';
import { AuthService } from 'core';

@Injectable({
  providedIn: 'root',
})
export class SsnService {
  private tries = 0;

  constructor(private readonly authService: AuthService, private readonly modalService: BsModalService) {}

  obtainSsnIfRequired(): Promise<void> {
    const userDetails = this.authService.getLoggedInUserDetails();
    if (userDetails?.ssnRequired && this.tries++ < 3) {
      const modal = this.modalService.show(SsnModalComponent, {
        ignoreBackdropClick: true,
        class: 'modal-md',
      });
      return firstValueFrom(
        modal.content!.$done.pipe(
          map((success) => {
            if (success) {
              userDetails.ssnRequired = false;
              this.authService.setLoggedInUserDetails(userDetails);
            }
          })
        )
      );
    }
    return Promise.resolve();
  }
}
