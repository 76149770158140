import { Directive, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, concat, defer, Observable, of, Subject } from 'rxjs';
import { debounceTime, map, shareReplay, startWith, switchAll, switchMap, takeUntil } from 'rxjs/operators';

import { Instrument } from '../entities/instrument';
import { CurrentMarketValue, SortOrder } from '../entities/current.market.value';
import { Currency } from '../entities/currency';
import { MarketService } from '../services/market.service';
import { MarketValueService } from '../services/market-value.service';
import { SelectedInstrumentService } from '../services/selected-instrument.service';
import { alwaysCombineLatest } from '../utils/rxjs';

/** This exists to abstract out common market logic - app specific components will extend it */
@Directive()
export class MarketsDirective implements OnDestroy {
  instrumentQueryControl = new UntypedFormControl('');

  $termCurrencies: Observable<Array<string>> = this.marketService.termCurrencies.pipe(
    // Could sort twice with simpler comparitors but only ES2019 requires sort to be stable (https://tc39.es/ecma262/#sec-array.prototype.sort)
    map((currencies) => currencies.sort(alphabeticallyPrioritiseUsd))
  );

  selectedCurrencyControl = new UntypedFormControl('');
  $selectedCurrency: Observable<string> = this.selectedCurrencyControl.valueChanges.pipe(
    // This must replay as it's value is set (in the extending component) before the template has attached
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private instrumentQuery = defer(() =>
    this.instrumentQueryControl.valueChanges.pipe(
      debounceTime(200), // 0.2s
      startWith(this.instrumentQueryControl.value)
    )
  );

  // ----------------------------------------
  // These are higher-order streams partitioned by the selected currency so that the display can be synchronised
  // correctly when switching currencies

  // instruments with the selected term currency and name substring-match
  private $instrumentsByCurrency: Observable<Observable<Array<Instrument>>> = this.$selectedCurrency.pipe(
    map((targetCurrency) =>
      combineLatest([this.marketService.instruments, this.instrumentQuery]).pipe(
        map(([instruments, instrumentQuery]) =>
          instruments.filter((instrument) => instrument.termCurrency.id === targetCurrency && instrument.matches(instrumentQuery))
        )
      )
    )
  );

  protected marketSort = new BehaviorSubject<SortOrder>({ field: 'instrument', asc: true });

  protected $sortedMarketsByCurrency: Observable<Observable<Array<CurrentMarketValue>>> = this.$instrumentsByCurrency.pipe(
    map(($instruments) => {
      const $markets = $instruments.pipe(
        switchMap((instruments) =>
          alwaysCombineLatest(instruments.map((instrument) => this.marketValueService.getAlwaysCurrentMarketValue(instrument)))
        )
      );
      // sort whenever the sort type or market values change
      return combineLatest([$markets, this.marketSort]).pipe(
        map(([markets, marketSort]) => markets.sort(CurrentMarketValue.getComparator(marketSort)))
      );
    })
  );

  $displayMarkets: Observable<Array<CurrentMarketValue>> = this.$sortedMarketsByCurrency.pipe(
    switchMap(($sortedMarkets) =>
      concat(
        // blank the displayed markets when changing currency
        of(new Array<CurrentMarketValue>()),
        $sortedMarkets
      )
    )
  );

  protected $done = new Subject<void>();

  constructor(
    protected readonly marketService: MarketService,
    private readonly marketValueService: MarketValueService,
    protected readonly instrumentService: SelectedInstrumentService
  ) {
    // inform other components about the available instruments
    this.$instrumentsByCurrency
      .pipe(switchAll(), takeUntil(this.$done))
      .subscribe((instruments) => this.instrumentService.displayInstruments(instruments));
  }

  ngOnDestroy(): void {
    this.$done.next();
  }

  selectCurrency(currency: string): void {
    this.selectedCurrencyControl.setValue(currency);
  }
}

// Optimised as we know that the values being sorted are also unique
export const alphabeticallyPrioritiseUsd = (c1: string, c2: string) => {
  if (c1 === Currency.USD) {
    return -1;
  } else if (c2 === Currency.USD) {
    return 1;
  }
  return c1.localeCompare(c2);
};
