import { HttpHeaders } from '@angular/common/http';
import { defined } from './runtypes';

export class HttpOptions {
  private headers: { [name: string]: string } = {};

  withOTP(otp?: number | null): HttpOptions {
    if (defined(otp)) {
      this.headers['cz-otp'] = `${otp}`;
    }
    return this;
  }

  withStructuredErrors(): HttpOptions {
    this.headers['cz-structured-errors'] = 'true';
    return this;
  }

  build(): { headers?: HttpHeaders } {
    if (Object.keys(this.headers).length > 0) {
      return { headers: new HttpHeaders(this.headers) };
    } else {
      return {};
    }
  }

  static builder(): HttpOptions {
    return new HttpOptions();
  }
}
