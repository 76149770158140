import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import * as R from 'runtypes';
import { Static } from 'runtypes';

import { URLS } from './app.constant';
import { Currency, CurrencyType } from '../entities/currency';
import { Instrument } from '../entities/instrument';
import { AuthService } from './auth.service';
import { defined, Enum } from '../utils/runtypes';

const ApiExplorerUrl = R.Record({
  network: R.String,
  url: R.String,
}).asReadonly();
type ApiExplorerUrl = Static<typeof ApiExplorerUrl>;

const ApiCurrency = R.Record({
  compositeAddress: R.Boolean,
  compositeAddressDisplayName: R.String.nullable(),
  compositeAddressStringTagName: R.String.nullable(),
  currencyNm: R.String,
  currencyTypeEn: Enum(CurrencyType),
  displayUnitsQty: R.Number,
  id: R.String,
  minorUnitsQty: R.Number,
  publicFg: R.Boolean,
  collateralFg: R.Boolean,
  spendFg: R.Boolean,
  stamp: R.Number,
  networks: R.Array(R.String),
  walletExplorerUrls: R.Array(ApiExplorerUrl),
  txnExplorerUrls: R.Array(ApiExplorerUrl),
});

const ApiInstrument = R.Record({
  id: R.String,
  baseCurrencyCd: R.String,
  termCurrencyCd: R.String,
  maxPricePrecision: R.Number,
  maxQuantityPrecision: R.Number,
  publicFg: R.Boolean,
  issueOnlyFg: R.Boolean,
  minTradeAmt: R.Number,
  maxTradeAmt: R.Number,
  marketSlippageFactor: R.Number,
  maxLeverage: R.Number.nullable(),
});
type ApiInstrument = Static<typeof ApiInstrument>;

@Injectable({
  providedIn: 'root',
})
export class MarketService {
  public readonly currencies: Observable<Array<Currency>> = this.http.get(URLS.currenciesUrl).pipe(
    map(R.Array(ApiCurrency).check),
    map((response) =>
      response.map(
        (r) =>
          new Currency(
            r.id,
            r.currencyNm,
            r.currencyTypeEn,
            r.minorUnitsQty,
            r.displayUnitsQty,
            r.networks,
            r.publicFg,
            r.collateralFg,
            r.spendFg,
            toExploreUrlMap(r.walletExplorerUrls),
            toExploreUrlMap(r.txnExplorerUrls),
            r.compositeAddress,
            r.compositeAddressDisplayName,
            r.compositeAddressStringTagName
          )
      )
    ),
    shareReplay(1)
  );

  private readonly allInstruments: Observable<Array<Instrument>> = combineLatest([
    this.currencies.pipe(map((currencies) => new Map(currencies.map((currency) => [currency.id, currency])))),
    this.http.get(URLS.instrumentsUrl).pipe(map(R.Array(ApiInstrument).check)),
    // refresh every time the logged in status changes
    this.authService.$loggedIn.pipe(
      // fetch on first load
      startWith(true)
    ),
  ]).pipe(
    map(([currenciesById, instruments]) =>
      instruments
        // exclude any instruments which use currencies that aren't available
        .map((r) => [r, currenciesById.get(r.baseCurrencyCd), currenciesById.get(r.termCurrencyCd)] as const)
        .filter((d): d is [ApiInstrument, Currency, Currency] => {
          const [_r, baseCurrency, termCurrency] = d;
          return defined(baseCurrency) && defined(termCurrency);
        })
        .map(
          ([r, baseCurrency, termCurrency]) =>
            new Instrument(
              r.id,
              baseCurrency,
              termCurrency,
              r.maxPricePrecision,
              r.maxQuantityPrecision,
              r.publicFg,
              r.issueOnlyFg,
              r.minTradeAmt,
              r.maxTradeAmt,
              r.marketSlippageFactor,
              r.maxLeverage
            )
        )
    ),
    shareReplay(1)
  );

  /**
   * The list of tradeable instruments. Each instrument is a pair of currencies
   * e.g. BTC/USD.
   */
  public readonly instruments: Observable<Array<Instrument>> = this.allInstruments.pipe(
    map((instruments) => instruments.filter((i) => i.publicFg || this.authService.seePrivateIntruments))
  );

  public readonly termCurrencies: Observable<Array<string>> = this.instruments.pipe(
    map((instruments) => Array.from(new Set(instruments.map((instrument) => instrument.termCurrency.id)).values()))
  );

  constructor(private readonly http: HttpClient, private readonly authService: AuthService) {}

  /**
   * Returns the list of supported currencies
   */
  public getCurrencies(): Promise<Currency[]> {
    return firstValueFrom(this.currencies);
  }

  public async getCurrency(symbol: string): Promise<Currency> {
    const currencies = await this.getCurrencies();
    const currency = currencies.find((i) => i.id == symbol);
    if (currency) {
      return currency;
    }
    throw new Error('Unknown currency ' + symbol);
  }

  public findInstrument(symbol: string, allowPrivate = false): Observable<Instrument> {
    return (allowPrivate ? this.allInstruments : this.instruments).pipe(
      map((instruments) => {
        const instrument = instruments.find((i) => i.id === symbol);
        if (instrument) {
          return instrument;
        }
        throw new Error('Unknown instrument ' + symbol);
      })
    );
  }

  public async getInstrument(symbol: string): Promise<Instrument> {
    return firstValueFrom(this.findInstrument(symbol));
  }
}

const toExploreUrlMap = (urls: Array<ApiExplorerUrl>) => new Map(urls.map((url) => [url.network, url.url]));
