<div class="add-card modal-lg">
  <div class="update-text" *ngIf="forUpdate">
    Your CoinZoom profile has been changed since you last used this card. We need to do some extra checks. Please re-enter the card details.
  </div>
  <div class="form-div">
    <div class="iframe-container" *ngIf="iframeSafeURL">
      <iframe [src]="iframeSafeURL"></iframe>
    </div>
  </div>
</div>
