import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { AuthService } from 'core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-session-expired',
  templateUrl: 'session-expired.component.html',
  styleUrls: ['session-expired.component.scss'],
})
export class SessionExpiredComponent implements OnInit {
  public static isShown = false;

  constructor(private readonly modalRef: BsModalRef, private readonly authService: AuthService, private readonly router: Router) {}

  ngOnInit(): void {
    SessionExpiredComponent.isShown = true;
    this.modalRef.onHidden?.subscribe((event) => this.onHideSessionExpirePopup(event));
  }

  public hideModal(): void {
    this.modalRef.hide();
  }

  public reLogin(): void {
    this.modalRef.hide();
    this.authService.clearAuthData();
    this.router.navigateByUrl('login');
  }

  onHideSessionExpirePopup(event: any): void {
    SessionExpiredComponent.isShown = false;
    if (event === 'backdrop-click') {
      this.authService.clearAuthData();
      this.router.navigate(['']);
      location.reload();
    }
  }
}
