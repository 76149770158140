import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import * as R from 'runtypes';
import { Static, Union } from 'runtypes';

import { URLS } from './app.constant';
import { KycStatus } from './auth.service';
import { SuccessOnlyResponse, ResourceResponseWithErrorDetails, ResourceResponse } from '../utils/utils';
import { LimitedUserData, UserData } from './session.service';
import { Enum, Missing } from '../utils/runtypes';

export class SendVerificationSMSRequest {
  constructor(readonly countryId: number | string, readonly mobileNumber: string) {}
}

export type PreTaxIncomeRange = {
  from: number;
  to: number | null;
  range: string;
};
export const preTaxIncomeDataConst: Array<PreTaxIncomeRange> = [
  { from: 0, to: 50000, range: '$0 - $50,000' },
  { from: 50000, to: 100000, range: '$50,000 - $100,000' },
  { from: 100000, to: 200000, range: '$100,000 - $200,000' },
  { from: 200000, to: 350000, range: '$200,000 - $350,000' },
  { from: 350000, to: 500000, range: '$350,000 - $500,000' },
  { from: 500000, to: null, range: '$500,000' },
];

const ApiUserProfile = R.Record({
  // there's a lot more but we currently only care about...
  userKycProfile: R.Record({
    kycStatus: Enum(KycStatus).nullable(),
  }).nullable(),
});
export type UserProfile = Static<typeof ApiUserProfile>;

const ApiUserProfileResponse = ResourceResponseWithErrorDetails(ApiUserProfile, R.Dictionary(R.String, R.String)).And(
  Union(
    R.Record({
      isSuccess: R.Literal(true),
      authorization: R.String,
    }),
    R.Record({
      isSuccess: R.Literal(false),
    })
  )
);

const ApiUserProfileValidated = R.Record({
  requiresKyc: R.Boolean,
  requiresMobileVerification: R.Boolean,
});
export type UserProfileValidated = Static<typeof ApiUserProfileValidated>;

const ApiUserProfileRejected = R.Dictionary(R.String);

const ApiUserProfileValidatedResponse = ResourceResponseWithErrorDetails(ApiUserProfileValidated, ApiUserProfileRejected);

const SmsVerificationResponse = SuccessOnlyResponse(R.String);

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(public http: HttpClient) {}

  saveUserProfile(
    profile: UserData,
    phoneVerificationCode: string | null,
    phoneVerificationToken: string | null,
    handleChanged: boolean,
    basicKyc?: boolean
  ): Observable<
    ResourceResponse<UserProfile, Record<string, string>> & ({ isSuccess: true; authorization: string } | { isSuccess: false })
  > {
    return this.http
      .post(URLS.profileUrl, {
        ...profile,
        phoneVerificationCode,
        phoneVerificationToken,
        handleChanged,
        basicKyc,
      })
      .pipe(map(ApiUserProfileResponse.check));
  }

  validateUserProfile(
    profile: UserData,
    phoneVerificationCode: string | null,
    phoneVerificationToken: string | null,
    handleChanged: boolean,
    basicKyc?: boolean
  ): Observable<ResourceResponse<UserProfileValidated, Record<string, string>>> {
    return this.http
      .post(URLS.validateProfileUrl, {
        ...profile,
        phoneVerificationCode,
        phoneVerificationToken,
        handleChanged,
        basicKyc,
      })
      .pipe(map(ApiUserProfileValidatedResponse.check));
  }

  saveLimitedUserProfile(
    profile: LimitedUserData,
    phoneVerificationCode: string | null,
    phoneVerificationToken: string | null,
    handleChanged: boolean
  ): Observable<
    ResourceResponse<UserProfile, Record<string, string>> & ({ isSuccess: true; authorization: string } | { isSuccess: false })
  > {
    return this.http
      .post(URLS.limitedProfileUrl, {
        ...profile,
        phoneVerificationCode,
        phoneVerificationToken,
        handleChanged,
      })
      .pipe(map(ApiUserProfileResponse.check));
  }

  sendSmsVerification(request: SendVerificationSMSRequest): Observable<SuccessOnlyResponse<string>> {
    return this.http.post(URLS.sendPhoneVerificationCodeUrl, request).pipe(map(SmsVerificationResponse.check));
  }

  getSuggestedAccountHandle(email: string): Observable<Array<string>> {
    return this.http.get<Array<string>>(URLS.suggestHandle + email);
  }

  submitSsn(socialSecurityNumber: string): Observable<Omit<ResourceResponse<unknown>, 'responseObject'>> {
    return this.http.post(URLS.submitSsn, { socialSecurityNumber }).pipe(map(ResourceResponse(Missing).check));
  }
}
