import { Subscription } from 'rxjs';
import { Guid } from 'guid-typescript';

import { Instrument } from './instrument';
import { ApiTwentyFourHourCandle } from '../services/market-data.service';
import { defined, KeyOf } from '../utils/runtypes';

class PricePromise {
  constructor(
    readonly id: string,
    readonly resolve: (value: number | PromiseLike<number>) => void,
    readonly reject: (reason: unknown) => void
  ) {}
}

export type SortFields = 'instrument' | 'price' | 'change' | 'volume';
export type SortOrder = {
  field: SortFields;
  asc: boolean;
};

export class SimpleCurrentMarketValue {
  price?: number;
  volume?: number;
  change?: number;
  high?: number;
  low?: number;
  bestAsk?: number;
  bestBid?: number;

  protected pricePromises: PricePromise[] = new Array<PricePromise>();

  constructor(protected symbol: string) {}

  public processPriceInfo(message: ApiTwentyFourHourCandle): this {
    const [_symbol, ...rawData] = message;
    const [open, high, low, close, volume, bid, ask] = rawData.map((d) => (d && (isNaN(d) || !isFinite(d)) ? undefined : d));

    this.price = close;
    this.volume = volume;
    this.change = open && close ? ((close - open) * 100) / open : undefined;
    this.high = high;
    this.low = low;
    this.bestBid = bid;
    this.bestAsk = ask;

    if (defined(close)) {
      this.pricePromises.forEach((pp) => pp.resolve(close));
      this.pricePromises = new Array<PricePromise>();
    }

    return this;
  }
}

export class CurrentMarketValue extends SimpleCurrentMarketValue {
  termToUsdMarketValue?: SimpleCurrentMarketValue;
  usdEquivalentInstrument?: Instrument;

  subscription: Subscription;

  get isUnstableBuy() {
    return !defined(this.bestAsk) || !defined(this.bestBid) || this.bestAsk > this.bestBid * (1 + this.instrument.marketSlippageFactor);
  }

  get isUnstableSell() {
    return !defined(this.bestAsk) || !defined(this.bestBid) || this.bestBid < this.bestAsk * (1 - this.instrument.marketSlippageFactor);
  }

  constructor(readonly instrument: Instrument) {
    super(instrument.id);
  }

  getPrice(timeOutMs = 5000): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      if (this.price) {
        resolve(this.price);
      } else {
        const id = Guid.create().toString();
        this.pricePromises.push(new PricePromise(id, resolve, reject));
        setTimeout(() => {
          if (this.pricePromises.filter((pp) => pp.id == id)) {
            this.pricePromises = this.pricePromises.filter((pp) => pp.id != id);
            reject(`No price available for ${this.symbol}`);
          }
        }, timeOutMs);
      }
    });
  }

  public get usdEquivalent(): number | null {
    return this.termToUsdMarketValue?.price && this.price ? this.price * this.termToUsdMarketValue.price : null;
  }

  public get positiveChange(): boolean {
    return defined(this.change) && this.change >= 0;
  }

  public static getComparator(order: SortOrder): (m1: CurrentMarketValue, m2: CurrentMarketValue) => number {
    switch (order.field) {
      case 'instrument':
        return (m1: CurrentMarketValue, m2: CurrentMarketValue) => (order.asc ? 1 : -1) * comparator(m1.instrument.id, m2.instrument.id);
      case 'price':
        return comparatorFor('price', order.asc);
      case 'change':
        return comparatorFor('change', order.asc);
      case 'volume':
        return comparatorFor('volume', order.asc);
    }
  }
}

const comparatorFor =
  (field: NonNullable<KeyOf<CurrentMarketValue, number | undefined>>, asc: boolean) => (m1: CurrentMarketValue, m2: CurrentMarketValue) =>
    (asc ? 1 : -1) * comparator(m1[field] || 0, m2[field] || 0);

const comparator = <T extends number | string>(a: T, b: T) => (a > b ? 1 : a < b ? -1 : 0);
