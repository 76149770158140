import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'messageTime',
})
export class MessageTimePipe implements PipeTransform {
  transform(input: any): any {
    if (!input) {
      return '';
    } else {
      const d = new Date(input);
      return d.toLocaleTimeString();
    }
  }
}
