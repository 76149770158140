import { Routes } from '@angular/router';
import { environment } from '../environments/environment';

import { NotFoundComponent } from './core/not-found/not-found.component';

export const AppRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'landing',
  },
  {
    path: 'landing',
    loadChildren: environment.simple
      ? () => import('./prices/prices.module').then((m) => m.PricesModule)
      : () => import('./landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: 'chartpopout/:symbol',
    redirectTo: '/landing/chartpopout/:symbol',
  },
  {
    path: 'forget-password',
    pathMatch: 'full',
    redirectTo: 'authentication/forget-password',
  },
  {
    path: 'risk',
    pathMatch: 'full',
    redirectTo: 'authentication/risk',
  },
  {
    path: 'PrivacyPolicy',
    pathMatch: 'full',
    redirectTo: 'authentication/PrivacyPolicy',
  },
  {
    path: 'WebRisk',
    pathMatch: 'full',
    redirectTo: 'authentication/WebRisk',
  },
  {
    path: 'WebPrivacyPolicy',
    pathMatch: 'full',
    redirectTo: 'authentication/WebPrivacyPolicy',
  },
  {
    path: 'authentication',
    loadChildren: () => import('./session/session.module').then((m) => m.SessionModule),
  },
  {
    path: 'portfolio',
    loadChildren: () => import('./portfolio/portfolio.module').then((m) => m.PortfolioModule),
  },
  {
    path: 'earn',
    loadChildren: () => import('./portfolio/portfolio.module').then((m) => m.PortfolioModule),
  },
  {
    path: 'margin',
    loadChildren: () => import('./portfolio/portfolio.module').then((m) => m.PortfolioModule),
  },
  {
    path: 'transaction-history',
    loadChildren: () => import('./transactions/transactions.module').then((m) => m.TransactionsModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  // Top menu is not flat unfortunately as ZOOM links to a sub-page of Settings
  {
    path: 'zoom',
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'mobile',
    loadChildren: () => import('./mobile/mobile.module').then((m) => m.MobileModule),
  },
  {
    path: 'web-chart/:symbol',
    redirectTo: '/mobile/web-chart/:symbol',
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: '', // don't add anything to the path
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];
