import * as R from 'runtypes';
import { Enum } from '../utils/runtypes';
import { BankCard } from '../services/threeDSecure.service';

export enum PaymentExecution {
  PAYMENT_CURRENCY = 'PAYMENT_CURRENCY',
  OTHER_CURRENCY = 'OTHER_CURRENCY',
  FUND = 'FUND',
  CARD_ONLY = 'CARD_ONLY',
}

export enum RecurringUnit {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export interface AvailableFunds {
  currencyCd: string;
  balance: number;
  estBalancePaymentCcy: number;
  costToPay: number;
}

export interface PaymentSummary {
  paymentId: string;
  currencyCd: string;
  priceRt: number;
  description: string;
  returnUrl: string;
  availableFunds: Array<AvailableFunds>;
  paymentExecution: PaymentExecution;
  recurringUnit: RecurringUnit;
  recurringInterval: number;
  maxRecurringPrice: number;
  version: number;
  startTs: string;
  needBillingAddress?: boolean;
}

export interface TargetedPaymentSummary extends PaymentSummary {
  threeDScards: Array<BankCard>;
  tabaPayIframe: string;
}

export enum ConfirmationStatus {
  OK = 'OK',
  EXPIRED = 'EXPIRED',
  OUT_OF_DATE = 'OUT_OF_DATE',
}

export const ApiConfirmPaymentResponse = R.Record({
  status: Enum(ConfirmationStatus),
  confirmedPaymentCost: R.Number.nullable(),
}).asReadonly();

export type ConfirmPaymentResponse = R.Static<typeof ApiConfirmPaymentResponse>;
