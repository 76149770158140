import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastContainerDirective, ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AppComponent } from './app.component';
import { AppRoutes } from './app-routing.routing';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { MessagesComponent } from 'commons/messages/messages.component';
import { PipeModule } from 'commons/pipes/pipes.module';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { AuthenticatedHttpService } from 'core';
import { CommonsModule } from 'commons/commons.module';
import { SessionExpiredComponent } from './sessionexpired/session-expired.component';

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent, MessagesComponent, NotFoundComponent, SessionExpiredComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, // required animations module
    RouterModule.forRoot(
      AppRoutes,
      // , { enableTracing: true }  // help debug routing issues
      // , { enableTracing: true }  // help debug routing issues
{}
    ),
    HttpClientModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
    }),
    ToastContainerDirective,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PipeModule,
    MatProgressSpinnerModule,
    OverlayModule,
    NgMultiSelectDropDownModule.forRoot(),
    FontAwesomeModule,
    CommonsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticatedHttpService,
      multi: true,
    },
    { provide: 'googleTagManagerId', useValue: 'GTM-PJXD44D' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
