<div class="footer container-fluid">
  <hr class="footer-separater" />
  <div class="footerSub">
    <div class="row main">
      <div class="footer-left">
        <div class="listMenu">
          <ul class="eStlUc">
            <li class="eTmngr">
              <a id="page-about-us" href="https://www.coinzoom.com/about-us/" target="_blank" rel="noopener noreferrer">About Us</a>
            </li>
            <li class="eTmngr">
              <a id="page-legal" href="https://www.coinzoom.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Legal</a>
            </li>
            <li class="eTmngr">
              <a data-id="support-link" href="https://support.coinzoom.com" target="_blank" rel="noopener noreferrer">Support</a>
            </li>
            <li class="eTmngr">
              <a id="page-contact" href="https://support.coinzoom.com/support/tickets/new" target="_blank" rel="noopener noreferrer">
                Contact
              </a>
            </li>
            <li id="page-api-docs" class="eTmngr">
              <a href="https://api-docs.coinzoom.com" target="_blank" rel="noopener noreferrer">API</a>
            </li>
            <li id="page-faq" class="eTmngr">
              <a href="https://support.coinzoom.com/support/home" target="_blank" rel="noopener noreferrer">FAQs</a>
            </li>
            <li id="page-status" class="eTmngr">
              <a href="https://statuspage.coinzoom.com" target="_blank" rel="noopener noreferrer">Status</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="footer-middle copyright-text">
        <p id="cr-coinzoom" class="copyright-content">&copy;{{ currentYear }} CoinZoom</p>
      </div>

      <div class="footer-right justify-content-end">
        <a id="link-twitter" href="https://twitter.com/GetCoinZoom" target="_blank" rel="noopener noreferrer">
          <fa-icon [icon]="faTwitter"></fa-icon>
        </a>
        <a id="link-facebook" href="https://www.facebook.com/CoinZoom/" target="_blank" rel="noopener noreferrer">
          <fa-icon [icon]="faFacebook"></fa-icon>
        </a>
        <a id="link-linkedin" href="https://www.linkedin.com/company/18405105/" target="_blank" rel="noopener noreferrer">
          <fa-icon [icon]="faLinkedin"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="account-info-message" *ngIf="exchangeBlocked" id="disabledAccount">
  <img class="pull-right-img mr-10" height="20px" width="20px" src="/assets/image/error.png" />
  This account is disabled
  <button class="btn btn-enable" (click)="enableAccount()">View Settings</button>
</div>
