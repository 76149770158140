import BigNumber from 'bignumber.js';
import { zeroPadNumberForDisplay } from '../utils/number';
import { Currency } from './currency';

export class Instrument {
  private _leverageOptions = Array<number>();

  constructor(
    readonly id: string,
    readonly baseCurrency: Currency,
    readonly termCurrency: Currency,
    readonly maxPricePrecision: number,
    readonly maxQuantityPrecision: number,
    readonly publicFg: boolean,
    readonly issueOnlyFg: boolean,
    readonly minTradeAmt: number,
    readonly maxTradeAmt: number,
    readonly marketSlippageFactor: number,
    readonly maxLeverage: number | null
  ) {
    if (maxLeverage != null) {
      for (let l = 1; l <= maxLeverage; l++) {
        this._leverageOptions.push(l);
      }
    }
  }

  public toString(): string {
    return `Instrument[id=${this.id}, baseCurrency=${this.baseCurrency}, termCurrency=${this.termCurrency}, maxPricePrecision=${this.maxPricePrecision}, maxQuantityPrecision=${this.maxQuantityPrecision}, publicFg=${this.publicFg}, issueOnlyFg=${this.issueOnlyFg}, minTradeAmt=${this.minTradeAmt}, maxTradeAmt=${this.maxTradeAmt}]`;
  }

  matches(searchString: string): boolean {
    return searchString == null || searchString.trim().length == 0 || this.id.toLowerCase().indexOf(searchString.trim().toLowerCase()) >= 0;
  }

  displayAmount(amount?: BigNumber | number | null, decimals = this.maxQuantityPrecision): string {
    return this.baseCurrency.displayAmount(amount, decimals);
  }

  displayPrice(price?: number | null): string {
    return zeroPadNumberForDisplay(price, this.maxPricePrecision);
  }

  scale(price: number): number {
    // TODO apply synthetic instrument scaling
    return price;
  }

  get leverageOptions(): Array<number> {
    return this._leverageOptions;
  }

  trimAmount(amount: number): number {
    return Number(amount.toFixed(this.maxQuantityPrecision));
  }

  trimPrice(price: number): number {
    return Number(price.toFixed(this.maxPricePrecision));
  }
}
