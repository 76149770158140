import { FeeTypes } from './fees';

export class CardDetails {
  accountNumber: number;
  month: number;
  year: number;
  securityCode: string;
}

export class CardTransaction {
  card: CardDetails = new CardDetails();
  amount: number;
  feePaymentMethod: FeeTypes;
  otp: number | null;
  paymentId: string;
}

export class CardTransactionResponse {
  success: boolean;
  errorMessages: Array<string>;
}
