<div class="modal-lg">
  <div class="head">
    <div style="text-align: center">
      <img itemprop="image" class="logo logo-dark mt-x1" alt="CoinZoom Exchange" src="./assets/image/icons/logo_white.svg" width="200" />
    </div>
    <span class="close-button">
      <button type="button" class="close pull-right" aria-label="Close" (click)="close()">X</button>
    </span>
  </div>
  <div class="card-body">
    <p>
      As part of CoinZoom’s annual compliance and security procedures, you will need to confirm the last four digits of your Social Security
      Number.
    </p>
    <p>
      CoinZoom has gone to extensive lengths to keep your account secure. Your social security number (SSN) is encrypted before it is
      stored.
    </p>
    <p>Thank you for being a valuable CoinZoom customer.</p>

    <form (ngSubmit)="submit()" #form="ngForm" autocomplete="off">
      <mat-form-field class="w-100 p-3" style="font-size: 16px">
        <mat-label>Last 4 digits of SSN</mat-label>
        <input
          matInput
          id="ssn"
          name="ssn"
          [(ngModel)]="model.ssn"
          #ssn="ngModel"
          (keydown)="onlyNumericAllowed($event)"
          required
          maxlength="4"
          minlength="4"
          placeholder="1234"
        />
        <mat-error *ngIf="ssn.errors?.required">Please enter the last 4 digits of your SSN</mat-error>
        <mat-error *ngIf="ssn.errors?.minlength">All of the last 4 digits are required</mat-error>
      </mat-form-field>
      <div class="btn-div">
        <button type="button" (click)="close()" class="btn btn-danger">Close</button>
        <button class="btn btn-success submit border-green float-right" [disabled]="form.invalid">Submit</button>
      </div>
    </form>
  </div>
</div>
