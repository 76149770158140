import { Component } from '@angular/core';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { MessageService, MessageStatus } from 'services/message/message.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent {
  faTrash = faTrashAlt;
  MessageStatus = MessageStatus;

  constructor(public messageService: MessageService) {}

  clearMessages(): void {
    this.messageService.clear();
  }
}
