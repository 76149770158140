import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { OrderDetailsComponent } from './order-details/order-details.component';
import { PipeModule } from './pipes/pipes.module';
import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ConfirmEqualValidatorDirective } from './directive/confirm-password.directive';
import { SvgIconsDirective } from './directive/svg-icon.directive';
import { ThemeSensitiveDirective } from './directive/theme-sensitive.directive';
import { NumberOnlyDirective } from './directive/number-only.directive';
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog/confirm-delete-dialog.component';
import { OtpModalComponent } from './otp-modal/otp-modal.component';
import { SsnModalComponent } from './ssn-modal/ssn-modal.component';
import { CzModule } from 'core';
import { CollateralComponent } from './collateral/collateral.component';
import { CollateralTransferComponent } from './collateral/collateral-transfer/collateral-transfer.component';
import { OrderEntryComponent } from './order-entry/order-entry.component';
import { OrderConfirmationComponent } from './order-entry/order-confirmation/order-confirmation.component';
import { OrderSettingsComponent } from 'commons/order-entry/order-settings/order-settings.component';
import { PositionCloseComponent } from './position-close/position-close.component';
import { PositionClosingOrderTypePipe } from './position-closing-order-type.pipe';
import { ExternalRedirectComponent } from './external-redirect/external-redirect.component';
import { PositionTableComponent } from './position-table/position-table.component';
import { PositionFeesComponent } from './position-fees/position-fees.component';

// This module should contain only components, etc., which are to be reused in other pages as it's imported into every
// other module. As such everything declared is exported. Any component which needs to have private members should use a
// submodule which can be imported directly or reexported here.
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule,
    PipeModule,
    BsDatepickerModule,
    ModalModule.forRoot(),
    BsDropdownModule,
    ProgressbarModule.forRoot(),
    CzModule,
    ColorPickerModule,
    NgbTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
  ],
  providers: [],
  schemas: [],
  declarations: [
    OrderDetailsComponent,
    OrderEntryComponent,
    OrderConfirmationComponent,
    OrderSettingsComponent,
    SsnModalComponent,
    ModalDialogComponent,
    DateFilterComponent,
    SpinnerComponent,
    ConfirmEqualValidatorDirective,
    SvgIconsDirective,
    ThemeSensitiveDirective,
    NumberOnlyDirective,
    ConfirmDeleteDialogComponent,
    OtpModalComponent,
    CollateralComponent,
    CollateralTransferComponent,
    PositionTableComponent,
    PositionFeesComponent,
    PositionCloseComponent,
    PositionClosingOrderTypePipe,
    ExternalRedirectComponent,
  ],
  exports: [
    OrderDetailsComponent,
    OrderEntryComponent,
    SsnModalComponent,
    ModalDialogComponent,
    DateFilterComponent,
    SpinnerComponent,
    ConfirmEqualValidatorDirective,
    SvgIconsDirective,
    ThemeSensitiveDirective,
    NumberOnlyDirective,
    ConfirmDeleteDialogComponent,
    OtpModalComponent,
    PipeModule,
    CollateralComponent,
    PositionTableComponent,
    PositionFeesComponent,
    PositionCloseComponent,
    PositionClosingOrderTypePipe,
    ExternalRedirectComponent,
  ],
})
// FIXME this needs a name which doesn't look so much like Angular's CommonModule
export class CommonsModule {}
