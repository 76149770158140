import { AbstractControl, ValidatorFn } from '@angular/forms';
import { BigNumber } from 'bignumber.js';

export const CzValidators = {
  range:
    (min: number, max: BigNumber | number): ValidatorFn =>
    (control: AbstractControl) => {
      const val = toNumber(control.value);
      return val < min || BigNumber(max).lt(val)
        ? {
            range: {
              min,
              max,
            },
          }
        : null;
    },

  maxPrecision:
    (precision: number): ValidatorFn =>
    (control: AbstractControl) => {
      const val = toNumber(control.value);
      return val !== Number(val.toFixed(precision)) ? { maxPrecision: precision } : null;
    },
};

// Convert a number-like value while turning potential NaN into zero
const toNumber = (raw: number | string | null) => Number('0' + (raw || 0));
