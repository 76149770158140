import { NgModule } from '@angular/core';
import { MessageTimePipe } from './message.time.pipe.pipe';
import { LocalDateTimePipe } from './locale.date.time.pipe';
import { LocalDateRangePipe } from './locale.date.pipe';

@NgModule({
  declarations: [MessageTimePipe, LocalDateTimePipe, LocalDateRangePipe],
  imports: [],
  exports: [MessageTimePipe, LocalDateTimePipe, LocalDateRangePipe],
  providers: [],
})
export class PipeModule {}
