import { BigNumber } from 'bignumber.js';

export const ZERO = BigNumber(0);

export const zeroPadNumberForDisplay = (num?: BigNumber | number | null, decimals = 2): string => BigNumber(num ?? 0).toFormat(decimals);

/** Split off the trailing zeros after a DP in a number which has been prepared for display, ie., it's a string */
export const trailingZeros = (displayNum: string): { num: string; zeros: string } => {
  // special handling for no DP and just a zero
  if (!displayNum.includes('.')) {
    return { num: displayNum, zeros: '' };
  }

  // Find the first trailing zero, similar to the regexp /(0*)$/ but faster
  let i = displayNum.length;
  while (i > 0 && displayNum[i - 1] === '0') {
    i--;
  }

  return {
    num: displayNum.slice(0, i),
    zeros: displayNum.slice(i),
  };
};
