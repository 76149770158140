import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { extractError, MessageService as BaseMessageService } from 'core';

export enum MessageStatus {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

class Message {
  constructor(readonly status: MessageStatus, readonly time: Date, readonly message: string, readonly title?: string) {}
}

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  messages = new Array<Message>();

  private readonly notificationSubject = new Subject<HttpErrorResponse>();
  notifications = this.notificationSubject.asObservable();

  constructor(messageSource: BaseMessageService, private readonly toasterService: ToastrService) {
    messageSource.$sessionExpired.subscribe(this.notificationSubject);
  }

  public success(message: string, title?: string): void {
    if (message && message.length > 0) {
      this.messages.splice(0, 0, new Message(MessageStatus.SUCCESS, new Date(), message, title));
      this.toasterService.success(message, title);
    }
  }

  public warning(message: string, title?: string): void {
    if (message && message.length > 0) {
      this.messages.splice(0, 0, new Message(MessageStatus.WARNING, new Date(), message, title));
      this.toasterService.warning(message, title);
    }
  }

  public error(message: string, title?: string): void {
    this.messages.splice(0, 0, new Message(MessageStatus.ERROR, new Date(), message, title));
    this.toasterService.error(message, title, {
      // disableTimeOut: true,      // this option requires user to click on message box to remove...
      timeOut: 1000 * 10, // so instead just wait with a delay or click to remove
    });
  }

  public clear(): void {
    this.messages = new Array<Message>();
  }

  public get numberMessages(): number {
    return this.messages.length;
  }

  public showErrorMessage(error: unknown): Observable<never> {
    const errorMsg = extractError(error);
    if (errorMsg) {
      this.error(errorMsg);
    }

    return EMPTY;
  }
}
