import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import * as R from 'runtypes';

const FALLBACK_ERROR_MSG = 'Your transaction cannot be processed at the current time.';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private readonly sessionExpired = new Subject<HttpErrorResponse>();
  $sessionExpired = this.sessionExpired.asObservable();

  public sessionExpire(error: HttpErrorResponse): void {
    this.sessionExpired.next(error);
  }
}

export const extractError = (error: unknown): string | null => {
  if (typeof error === 'string') {
    return error;
  } else if (isHttpErrorType1(error) && error.status >= 502 && error.status <= 504) {
    return FALLBACK_ERROR_MSG;
  } else if (isNestedError(error)) {
    return error.error;
  } else if (isHttpErrorType2(error)) {
    if (error.error.httpStatus !== 401) {
      return error.error.message;
    } else {
      return null;
    }
  } else {
    console.log(error);
    return FALLBACK_ERROR_MSG;
  }
};

export const extractStructuredErrors = (errorResponse: unknown): Array<StructuredError> | null => {
  if (isStructuredErrorType(errorResponse) && errorResponse.status == 400 && errorResponse.error.errors.length > 0) {
    return errorResponse.error.errors;
  } else {
    return null;
  }
};

const ApiStructuredError = R.Record({
  code: R.String,
  message: R.String,
}).asReadonly();

export type StructuredError = R.Static<typeof ApiStructuredError>;

const isNestedError = R.Record({ error: R.String }).guard;
const isHttpErrorType1 = R.Record({ status: R.Number }).guard;
const isHttpErrorType2 = R.Record({ error: R.Record({ httpStatus: R.Number, message: R.String }) }).guard;
const isStructuredErrorType = R.Record({
  status: R.Number,
  error: R.Record({ errors: R.Array(ApiStructuredError) }),
}).guard;
