import * as R from 'runtypes';

export const ApiHeldFunds = R.Record({
  id: R.String,
  accountId: R.Number,
  currencyCd: R.String,
  eventTs: R.String,
  fiatTransferTypeEn: R.String,
  holdAmt: R.Number,
  holdUntilTs: R.String,
  feeAdjustedTransferAmt: R.Number,
}).asReadonly();

export type ApiHeldFunds = R.Static<typeof ApiHeldFunds>;

export const getDisplayHoldType = (funds: ApiHeldFunds): string => {
  if (
    funds.fiatTransferTypeEn === 'CARD_DEPOSIT'
    || funds.fiatTransferTypeEn === 'CARD_DEPOSIT_3DS'
    || funds.fiatTransferTypeEn === 'APPLE_PAY_DEPOSIT'
    || funds.fiatTransferTypeEn === 'GOOGLE_PAY_DEPOSIT'
  ) {
    return 'Card Deposit';
  } else if (funds.fiatTransferTypeEn === 'ACH_DEPOSIT' || funds.fiatTransferTypeEn === 'ACH_DIRECT_DEPOSIT') {
    return 'ACH Deposit';
  } else {
    return '';
  }
};
