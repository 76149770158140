import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BankCard } from '../../../services/threeDSecure.service';
import { NativeEventListenerService } from '../../../services/native-event-listener.service';

export type NewCard = BankCard & {
  token: string;
};

@Component({
  selector: 'cz-tabapay-add-card',
  templateUrl: './tabapay-add-card.component.html',
  styleUrls: ['./tabapay-add-card.component.scss'],
})
export class TabaPayAddCardComponent implements OnInit, OnDestroy {
  private static readonly IFRAME_MESSAGE_TYPE: string = 'message.coinzoom';

  @Input() iframeURL: string;
  @Input() forUpdate: BankCard | null;
  @Output() saved = new EventEmitter<NewCard>();
  @Output() errors = new EventEmitter<string>();

  iframeSafeURL: SafeResourceUrl;

  constructor(
    private readonly eventListenerService: NativeEventListenerService,
    private readonly sanitizer: DomSanitizer,
    private readonly modalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.iframeSafeURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.sanitizer.sanitize(SecurityContext.URL, this.iframeURL)!);
    this.eventListenerService.addEventListener(TabaPayAddCardComponent.IFRAME_MESSAGE_TYPE, this.possibleIframeMessageReceived.bind(this));
  }

  ngOnDestroy(): void {
    this.eventListenerService.removeEventListener(TabaPayAddCardComponent.IFRAME_MESSAGE_TYPE);
  }

  private possibleIframeMessageReceived(event: Event): void {
    if (event instanceof MessageEvent) {
      if (this.iframeURL.startsWith(event.origin)) {
        this.processIFrameMessage(event.data);
      }
    } else {
      // Not an event from the iframe
      return;
    }
  }

  private processIFrameMessage(data: string) {
    if (data !== 'Close') {
      if (data.slice(0, 7) === 'Error: ') {
        // Error
      } else {
        const asData = data.split('|');
        if (asData.length == 3) {
          const cardDigits = asData[0] as string;
          const expiryDate = asData[1] as string;
          const token = asData[2] as string;
          const binNumber = cardDigits.substring(0, 6);
          const last4Digits = cardDigits.substring(cardDigits.length - 4);

          if (
            this.forUpdate
            && (this.forUpdate.binNumber !== binNumber
              || this.forUpdate.last4Digits !== last4Digits
              || this.forUpdate.expiryDate !== expiryDate)
          ) {
            this.errors.emit('Please enter the full details for the card you previously saved.');
          } else {
            this.saved.emit({
              binNumber,
              last4Digits,
              maskedCard: `${binNumber}XXXXXX${last4Digits}`,
              expiryDate,
              token,
              needsUpdate: false,
              hasSuccessful3dsSession: this.forUpdate?.hasSuccessful3dsSession ?? false,
            });
            this.modalRef.hide();
          }
        } else {
          // Data Error
        }
      }
    } else {
      this.modalRef.hide();
    }
  }
}
