<div class="message-controls">
  <a (click)="clearMessages()">
    <fa-icon [icon]="faTrash"></fa-icon>
    <span>Clear all</span>
  </a>
</div>

<div class="messages">
  <div
    *ngFor="let message of messageService.messages"
    class="message"
    [class.success]="message.status === MessageStatus.SUCCESS"
    [class.warning]="message.status === MessageStatus.WARNING"
    [class.error]="message.status === MessageStatus.ERROR"
  >
    <div *ngIf="message.title" class="title">
      {{ message.title }}
    </div>
    <div class="message-text">
      {{ message.message }}
    </div>
    <div class="message-time">
      {{ message.time | messageTime }}
    </div>
  </div>

  <div class="no-messages" *ngIf="messageService.numberMessages === 0">No messages to display.</div>
</div>
