// These are used in both the model and API layers

import BigNumber from 'bignumber.js';

export enum OrderType {
  Market = 'MARKET',
  Limit = 'LIMIT',
  StopLimit = 'STOP_LIMIT',
  Stop = 'STOP',
  OCO = 'OCO',
}

export enum OrderStatus {
  New = 'NEW',
  PartiallyFilled = 'PARTIALLY_FILLED',
  Filled = 'FILLED',
  Cancelled = 'CANCELLED',
  Rejected = 'REJECTED',
}

export enum OrderSide {
  Buy = 'BUY',
  Sell = 'SELL',
}

export enum TimeInForce {
  FillOrKill = 'FOK',
  ImmediateOrCancel = 'IOC',
  GoodTilCanceled = 'GTC',
}

export const formattedOrderStatus = (orderStatusEn: OrderStatus) => {
  if (orderStatusEn === OrderStatus.Cancelled) {
    return 'Cancelled';
  } else if (orderStatusEn === OrderStatus.Filled) {
    return 'Filled';
  } else if (orderStatusEn === OrderStatus.PartiallyFilled) {
    return 'Partial Fill';
  } else if (orderStatusEn === OrderStatus.New) {
    return 'New';
  } else if (orderStatusEn === OrderStatus.Rejected) {
    return 'Rejected';
  } else {
    return '';
  }
};

export class OrderExecution {
  constructor(
    readonly orderId: string,
    readonly ocoOrderId: string | null,
    readonly transactTimeTs: string,
    readonly orderSideEn: OrderSide,
    readonly lastQuantityAmt: BigNumber,
    readonly cumulativeQuantityAmt: BigNumber,
    readonly averagePriceRt: number | null,
    readonly lastPriceRt: number | null,
    readonly orderStatusEn: OrderStatus,
    readonly rejectionReason: string | null,
    readonly leavesQuantityAmt: BigNumber | null
  ) {}

  get rejected(): boolean {
    return this.orderStatusEn === OrderStatus.Rejected;
  }

  get orderStatus(): string {
    return formattedOrderStatus(this.orderStatusEn);
  }
}

export type OrderExecutionNotification = {
  readonly execution: OrderExecution;
  readonly orderTypeEn: OrderType;
  readonly symbolCd: string;
  readonly priceRt?: number | null;
  readonly stopPriceRt?: number | null;
  readonly clientOrderId: string | null;
  readonly leverage: number | null;
  readonly closingPositionFg: boolean | null;
  readonly note: string | null;
};

export type OrderChangeResponse = {
  accountId: number;
  averagePriceRt: null;
  clientOrderId: null;
  cumulativeQuantityAmt: string;
  currencyCd: string;
  // executionTypeEn: "NEW",
  id: null;
  lastPriceRt: null;
  lastQuantityAmt: string;
  leavesQuantityAmt: string;
  orderId: string;
  ocoOrderId: string;
  orderSideEn: OrderSide;
  orderStatusEn: OrderStatus;
  orderTypeEn: OrderType;
  // partyCd: "CUST"
  priceRt: number;
  stopPriceRt: number;
  quantityAmt: string;
  rejectReason: null;
  response: null;
  symbolCd: string;
  transactTimeTs: string;
  note: string | null;
  leverage: number;
  closingPositionFg: boolean | null;
};
