import { Component, Input, OnChanges } from '@angular/core';
import { BigNumber } from 'bignumber.js';

import { Currency } from '../../../entities/currency';
import { trailingZeros } from '../../../utils/number';

@Component({
  selector: 'cz-currency-amount',
  templateUrl: './currency-amount.component.html',
  styleUrls: ['./currency-amount.component.scss'],
})
export class CurrencyAmountComponent implements OnChanges {
  @Input() amount?: BigNumber | number | null;
  @Input() currency?: Currency | null;
  @Input() highlightTrailingZeros = true;
  @Input() lenientRounding = false;
  @Input() ellipsis = false;

  displayedAmount: string;
  trailingZeros: string;

  ngOnChanges(): void {
    const amount = this.roundAmount();
    if (amount) {
      if (this.highlightTrailingZeros) {
        const display = trailingZeros(amount);
        this.displayedAmount = display.num;
        this.trailingZeros = display.zeros;
      } else {
        this.displayedAmount = amount;
        this.trailingZeros = '';
      }
    }
  }

  private roundAmount(): string | null {
    if (this.currency) {
      if (this.lenientRounding) {
        return this.currency.displayAmount(
          this.amount,
          Math.max(this.currency.displayUnitsQty, new BigNumber(this.amount ?? 0).decimalPlaces() ?? 0)
        );
      } else {
        return this.currency.displayAmount(this.amount);
      }
    } else {
      return null;
    }
  }
}
