import { ElementRef } from '@angular/core';

/**
 * Focus the provided element referenced in the template with, eg., #foo and picked up using @ViewChild
 */
export const focusElem = (elem: ElementRef): void => {
  if (elem) {
    elem.nativeElement.focus();
  }
};
