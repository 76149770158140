import * as R from 'runtypes';

export const ApiGatewayLimit = R.Record({
  maxAmountPerTxnAmt: R.Number.nullable(),
  maxAmountPerDayAmt: R.Number.nullable(),
  maxAmountPerWeekAmt: R.Number.nullable(),
  fiatTransferTypeEn: R.String,
}).asReadonly();

export type GatewayLimit = R.Static<typeof ApiGatewayLimit>;
