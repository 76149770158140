import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URLS } from './app.constant';
import { MarketService } from './market.service';
import { Currency } from '../entities/currency';
import { Ledger, LedgerType } from './ledger.service';
import { AuthService } from './auth.service';
import { defined } from '../utils/runtypes';
import { ZERO } from '../utils/number';

export type InterestEarned = {
  month: Date;
  interest: number;
};

export type InterestSummary = {
  interest: number;
  estimateInterestUsd: number;
};

type BonusRateTier = {
  qualifyingZoomAmt: number;
  bonusRatePct: number;
};

type ApiEarnInterestRate = {
  currencyCd: string;
  ledgerTypeEn: LedgerType;
  allowedToInvest: boolean;
  notAllowedToInvestReason: string | null;
  baseRatePct: number;
  bonusRatePct: number;
  bonusTiers: Array<BonusRateTier>;
  earned: Array<InterestEarned>;
  lifetimeInterest: InterestSummary;
  last30DayInterest: InterestSummary;
  stakingHoldHours: number | null;
};

type ApiEarnInterestInformation = {
  rates: Array<ApiEarnInterestRate>;
  bonusInterestEarned: Array<InterestEarned>;
  lifetimeBonusInterest: InterestSummary;
  masterLoanAgreementAccepted: boolean;
  masterLoanAgreementUrl: string;
};

export class CurrentEarnRate {
  constructor(
    readonly currency: Currency,
    readonly ledgerType: LedgerType,
    readonly allowedToInvest: boolean,
    readonly notAllowedToInvestReason: string | null,
    readonly apy: number,
    readonly bonusRatePct: number,
    readonly bonusTiers: Array<BonusRateTier>,
    readonly tradingLedger: Ledger,
    readonly interestEarnLedger: Ledger,
    readonly earned: Array<InterestEarned>,
    readonly lifetimeInterest: InterestSummary,
    readonly last30DayInterest: InterestSummary,
    readonly stakingHoldHours: number | null
  ) {}

  get hasInterestPayments(): boolean {
    return this.earned && this.earned.filter((e) => e.interest > 0).length > 0;
  }

  get hasBalance(): boolean {
    return (
      !this.tradingLedger.balance.eq(ZERO) || !this.tradingLedger.reservedBalance.eq(ZERO) || !this.interestEarnLedger.balance.eq(ZERO)
    );
  }
}

export class CurrentEarnRates {
  constructor(
    readonly rates: Array<CurrentEarnRate>,
    readonly lifetimeBonusInterest: InterestSummary,
    readonly bonusInterestEarned: Array<InterestEarned>,
    readonly acceptedAgreement: boolean,
    readonly masterLoanAgreementUrl: string
  ) {}
}

export class EarnTransfer {
  constructor(
    readonly currencyCd: string,
    readonly ledgerTypeEn: LedgerType,
    readonly amountAmt: number,
    readonly toEarn: boolean,
    readonly acceptedMasterLoanAgreement?: boolean | null
  ) {}
}

export type EarnTransferResponse = {
  success: boolean;
  errorMessage: string;
};

@Injectable({
  providedIn: 'root',
})
export class EarnService {
  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly marketService: MarketService
  ) {}

  public getCurrentRates(ledgers: Array<Ledger>): Observable<CurrentEarnRates> {
    return forkJoin([this.marketService.getCurrencies(), this.http.get<ApiEarnInterestInformation>(URLS.getCurrentEarnInterestRates)]).pipe(
      map(([currencies, rateInfo]) => this.buildRates(currencies, rateInfo, ledgers))
    );
  }

  public transfer(request: EarnTransfer): Observable<EarnTransferResponse> {
    return this.http.post<EarnTransferResponse>(URLS.earnTransfer, request);
  }

  private buildRates(currencies: Currency[], rateInfo: ApiEarnInterestInformation, ledgers: Ledger[]): CurrentEarnRates {
    const currenciesById = new Map<string, Currency>(currencies.map((c) => [c.id, c]));
    const tradeLedgersByCurrencyId = new Map<string, Ledger>(
      ledgers.filter((l) => l.ledgerType === LedgerType.TRADING).map((l) => [l.currency.id, l])
    );

    const ccyLedgerTypeKey = (ccy: string, ledgerType: LedgerType) => ccy + ':' + ledgerType;
    const interestEarnLedgersByCurrencyAndLedgerTypeId = new Map<string, Ledger>(
      ledgers
        .filter((l) => l.ledgerType === LedgerType.EARNING || l.ledgerType === LedgerType.STAKING)
        .map((l) => [ccyLedgerTypeKey(l.currency.id, l.ledgerType), l])
    );
    return new CurrentEarnRates(
      rateInfo.rates
        // exclude any rates which use currencies that aren't available
        .map((r) => [r, currenciesById.get(r.currencyCd)] as const)
        .filter((d): d is [ApiEarnInterestRate, Currency] => {
          const [_, currency] = d;
          return defined(currency);
        })
        .filter(([_, currency]) => currency.publicFg || this.authService.seePrivateIntruments)
        .map(([r, currency]) => {
          const tradeLedger = tradeLedgersByCurrencyId.get(r.currencyCd) || Ledger.buildZeroLedger(LedgerType.TRADING, currency);
          const interestEarningLedger =
            interestEarnLedgersByCurrencyAndLedgerTypeId.get(ccyLedgerTypeKey(r.currencyCd, r.ledgerTypeEn))
            || Ledger.buildZeroLedger(r.ledgerTypeEn, currency);

          return new CurrentEarnRate(
            currency,
            r.ledgerTypeEn,
            r.allowedToInvest,
            r.notAllowedToInvestReason,
            r.baseRatePct,
            r.bonusRatePct,
            r.bonusTiers,
            tradeLedger,
            interestEarningLedger,
            r.earned,
            r.lifetimeInterest,
            r.last30DayInterest,
            r.stakingHoldHours
          );
        })
        .sort((r1, r2) => {
          if (r1.currency.id > r2.currency.id) {
            return 1;
          } else if (r1.currency.id < r2.currency.id) {
            return -1;
          } else {
            return 0;
          }
        }),
      rateInfo.lifetimeBonusInterest,
      rateInfo.bonusInterestEarned,
      rateInfo.masterLoanAgreementAccepted,
      rateInfo.masterLoanAgreementUrl
    );
  }
}
