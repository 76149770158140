import { Injectable } from '@angular/core';
import { CommonEnums } from '../app.enums';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  /* The Angular way would be to have a directive which uses `@HostListener("wheel", ["$event"])` */
  handleMouseWheelEvent(): void {
    document.addEventListener('wheel', (event) => {
      const activeEle = event.target;
      if (activeEle instanceof HTMLInputElement && activeEle.type === 'number' && activeEle.classList.contains('noscroll')) {
        activeEle.blur();
      }
    });
  }

  hideShowPassword(passwordElementType: string): any {
    switch (passwordElementType) {
      case 'text':
        return CommonEnums.Password;
      case 'password':
        return CommonEnums.Text;
      default:
        break;
    }
  }
}
