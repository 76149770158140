<div class="modal-sm">
  <div class="otpsub">
    <div class="head">
      <div style="text-align: center">
        <img itemprop="image" class="logo logo-dark mt-x1" alt="CoinZoom Exchange" src="../assets/image/warning.png" width="100" />
      </div>
    </div>
    <div class="modal-body padding-custom by text-center">
      <div class="session-expired-label">Your session has expired. Please login again to continue.</div>
      <div class="session-expired-ctrls">
        <button (click)="hideModal()" class="btn btn-danger">Close</button>
        <button (click)="reLogin()" class="btn btn-success">Login</button>
      </div>
    </div>
  </div>
</div>
