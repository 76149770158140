<div class="text-center">
  <div class="page-not-found text-center">
    <div class="img-div text-center">
      <img src="./assets/image/icons/logo_white.svg" width="200" />
      <div class="text-center">
        <h1 class="error-code">404</h1>
        <h2 class="error-code">Sorry, Page not found</h2>
        <!-- <p style="font-size: 24px; margin-top: 20px;">We didn't find the page you are looking for. Go to our <a>home
            page </a>or go back to our <a>previous page</a></p> -->
        <button class="btn btn-success go-home" [routerLink]="['/']">Go Home</button>
      </div>
    </div>
  </div>
</div>
