import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { URLS } from 'commons/app.constant';
import { SessionService as BaseSessionService, ResourceResponse, LoginSuccess, AuthService } from 'core';

const VerificationResponse = ResourceResponse(LoginSuccess);

@Injectable({
  providedIn: 'root',
})
export class SessionService extends BaseSessionService {
  constructor(private readonly authService: AuthService, http: HttpClient) {
    super(http);
  }

  resendEmail(data: any): Observable<any> {
    const dataApi = {
      emailId: data.email,
    };
    return this.http.put(URLS.emailResend, dataApi);
  }

  forgotEmailSend(email: any): Observable<any> {
    return this.http.get(URLS.forgotPasswordEmailSend + encodeURIComponent(email));
  }

  forgotOtpVerify(otp: any, tokens: any): Observable<any> {
    const dataApi = {
      confirmationToken: tokens,
      otp,
    };
    return this.http.post(URLS.forgotPasswordOTP, dataApi);
  }

  forgetPassword(user: any): Observable<any> {
    const dataApi = {
      confirmPassword: user.confirmPassword,
      //"confirmationToken": this.authService.confirmationToken,
      passwordResetToken: this.authService.forgetPasswordToken,
      newPassword: user.password,
    };
    return this.http.put(URLS.forgetPassword, dataApi);
  }

  googleAuthQRCOde(): Observable<any> {
    return this.http.post(URLS.googleAuthAPI, {});
  }

  verification(verificationToken: string): Observable<ResourceResponse<LoginSuccess>> {
    return this.http.get(URLS.registerLinkVerify + verificationToken).pipe(map(VerificationResponse.check));
  }

  resendverificationLink(emailId: any): Observable<any> {
    const apiRequest = {
      emailId,
    };
    return this.http.put(URLS.resendLinkVerify, apiRequest);
  }
}
