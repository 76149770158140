<div id="headersection">
  <div id="myNav" class="overlay">
    <!-- Button to close the overlay navigation -->
    <a id="mobile-menu-close" href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>

    <!-- Overlay content -->
    <div id="mobile-menu" class="overlay-content">
      <a data-id="landing-page" [routerLink]="['/landing']" (click)="closeNav()" routerLinkActive="active-link">
        <span *ngIf="!simple">Trade</span><span *ngIf="simple">Prices</span>
      </a>

      <ng-container *ngIf="$loggedIn | async; else loggedOutLeft">
        <a (click)="goExternal('Curios')"> NFT Market <fa-icon [icon]="faExternal"></fa-icon> </a>
        <a data-id="transactions=page" (click)="closeNav()" [routerLink]="['/transaction-history']" routerLinkActive="active-link">
          Transactions
        </a>
        <a data-id="portfolio-page" (click)="closeNav()" [routerLink]="['/portfolio']" routerLinkActive="active-link"> Portfolio </a>
        <a data-id="settings-page" (click)="closeNav()" [routerLink]="['/settings']" routerLinkActive="active-link"> Settings </a>
        <a data-id="logout" (click)="logout(); closeNav()" routerLinkActive="active">Logout</a>
      </ng-container>

      <ng-template #loggedOutLeft>
        <a data-id="sign-up-page" (click)="closeNav()" [routerLink]="['signup']" routerLinkActive="active-link">Sign Up</a>
        <a data-id="sign-in-page" (click)="closeNav()" [routerLink]="['login']" routerLinkActive="active">Sign In</a>
      </ng-template>
    </div>
  </div>

  <div class="mobile-header container-fluid top-header-section" *ngIf="platform === Platform.IOS">
    <div class="row">
      <div class="col-md-12 open-link">
        <span class="mobile-header-text">CoinZoom</span>
        <a
          id="ios-store-link"
          href="https://apps.apple.com/us/app/coinzoom-buy-bitcoin-crypto/id1575983875?mt=8"
          style="
            display: inline-block;
            overflow: hidden;
            background: url(https://linkmaker.itunes.apple.com/en-gb/badge-lrg.svg?releaseDate=2020-03-05&kind=iossoftware&bubble=ios_apps)
              no-repeat;
            width: 135px;
            height: 40px;
          "
        ></a>
      </div>
    </div>
  </div>
  <div class="mobile-header container-fluid top-header-section" *ngIf="platform === Platform.Android">
    <div class="row">
      <div class="col-md-12 open-link">
        <span class="mobile-header-text">CoinZoom</span>
        <a id="android-store-link" href="https://play.google.com/store/apps/details?id=com.coinzoom.android" class="android-link">Open</a>
      </div>
    </div>
  </div>

  <div class="mobile-header container-fluid">
    <div class="row">
      <div id="mobile-menu-open" class="col-md-4 menu-toggle-icon" (click)="openNav()">
        <svg class="mobile-menu-icon" viewBox="0 0 512 512">
          <path
            d="M491.318,235.318H20.682C9.26,235.318,0,244.577,0,256s9.26,20.682,20.682,20.682h470.636
           c11.423,0,20.682-9.259,20.682-20.682C512,244.578,502.741,235.318,491.318,235.318z"
          />

          <path
            d="M491.318,78.439H20.682C9.26,78.439,0,87.699,0,99.121c0,11.422,9.26,20.682,20.682,20.682h470.636
           c11.423,0,20.682-9.26,20.682-20.682C512,87.699,502.741,78.439,491.318,78.439z"
          />

          <path
            d="M491.318,392.197H20.682C9.26,392.197,0,401.456,0,412.879s9.26,20.682,20.682,20.682h470.636
           c11.423,0,20.682-9.259,20.682-20.682S502.741,392.197,491.318,392.197z"
          />
        </svg>
      </div>
      <div class="col-md-8 img-wrapper">
        <img itemprop="image" class="logo logo-dark mt-x1" alt="CoinZoom Exchange" src="./assets/image/icons/logo_white.svg" width="140" />
      </div>
    </div>
  </div>

  <div id="top-menu" class="header justify-content-between">
    <nav class="navbar bg-light pt-0 pb-0">
      <a data-id="home-page" class="navbar-brand" [routerLink]="['/']">
        <img
          id="coinzoom-logo"
          itemprop="image"
          class="logo logo-dark mt-x1"
          alt="CoinZoom Exchange"
          src="./assets/image/icons/logo_white.svg"
          width="140"
      /></a>
    </nav>
    <nav class="navbar navbar-expand-lg navbar-light bg-light justify-content-end">
      <div class="navbar-right" id="navbarSupportedContent">
        <ul class="nav">
          <li class="nav-item">
            <a data-id="landing-page" class="nav-link cool-link" [routerLink]="['/landing']" routerLinkActive="active-link">
              <span *ngIf="!simple">Trade</span><span *ngIf="simple">Prices</span>
            </a>
          </li>

          <ng-container *ngIf="$loggedIn | async; else loggedOut">
            <li class="nav-item">
              <a (click)="goExternal('Curios')" class="nav-link cool-link"> NFT Market <fa-icon [icon]="faExternal"></fa-icon> </a>
            </li>
            <li class="nav-item" *ngIf="authService.seeEarn">
              <a
                data-id="transactions-page"
                class="nav-link cool-link"
                [routerLink]="['/earn']"
                [state]="{ wallet: 'earn' }"
                routerLinkActive="active-link"
              >
                Earn
              </a>
            </li>
            <li class="nav-item" *ngIf="authService.seeMarginTrading">
              <a
                data-id="transactions-page"
                class="nav-link cool-link"
                [routerLink]="['/margin']"
                [state]="{ wallet: 'margin' }"
                routerLinkActive="active-link"
              >
                Margin
              </a>
            </li>
            <li class="nav-item">
              <a
                data-id="portfolio-page"
                class="nav-link cool-link"
                [routerLink]="['/portfolio']"
                [state]="{ wallet: 'overview' }"
                routerLinkActive="active-link"
              >
                Portfolio
              </a>
            </li>
            <li class="nav-item">
              <a
                data-id="transactions-page"
                class="nav-link cool-link"
                [routerLink]="['/transaction-history']"
                routerLinkActive="active-link"
              >
                Transactions
              </a>
            </li>
            <li class="nav-item">
              <a data-id="settings-page" class="nav-link cool-link" [routerLink]="['/settings']" routerLinkActive="active-link">
                Settings
              </a>
            </li>
            <li class="nav-item">
              <a data-id="logout" class="nav-link cool-link" (click)="logout()" routerLinkActive="active">Logout</a>
            </li>
          </ng-container>

          <ng-template #loggedOut>
            <li class="nav-item">
              <a data-id="sign-up-page" class="nav-link cool-link" [routerLink]="['signup']" routerLinkActive="active-link">Sign Up</a>
            </li>
            <li class="nav-item">
              <a data-id="sign-in-page" class="nav-link cool-link show-signIn" [routerLink]="['login']" routerLinkActive="active-link">
                Sign In
              </a>
            </li>
          </ng-template>
        </ul>
      </div>
      <div class="icon-nav-side">
        <ul class="nav justify-content-end">
          <li *ngIf="themeable" class="nav-item">
            <a id="toggle-theme" data-toggle="tooltip" title="Change Theme" class="nav-link" (click)="toggleTheme()">
              <img *ngIf="theme === Theme.day" src="./assets/image/icons/sun.png" alt="bright theme" />
              <img *ngIf="theme === Theme.night" src="./assets/image/icons/moon.png" alt="dark theme" />
            </a>
          </li>
          <li class="nav-item">
            <div id="google_translate_element"></div>
          </li>
          <li class="nav-item">
            <a
              id="messages-toggle"
              class="message-toggle"
              data-toggle="tooltip"
              title="Show Messages"
              (click)="showMessages = !showMessages"
            >
              <fa-icon [icon]="faEnvelope" class="message-icon"></fa-icon>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>

  <app-messages *ngIf="showMessages"></app-messages>
</div>

<app-external-redirect [partner]="forExternal" [token]="authToken | async" [newTab]="true"></app-external-redirect>
