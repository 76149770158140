import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CurrencyAmountComponent } from './numeric/currency-amount/currency-amount.component';
import { InstrumentAmountComponent } from './numeric/instrument-amount/instrument-amount.component';
import { InstrumentPriceComponent } from './numeric/instrument-price/instrument-price.component';
import { NumericDisplayComponent } from './numeric/numeric-display/numeric-display.component';
import { NoNumericSpinDirective } from './no.numeric.spin.directive';
import { NumberFormatDirective } from './number-format.directive';
import { TabaPayAddCardComponent } from './components/new-tabapay-card/tabapay-add-card.component';

const exportedComponents = [
  CurrencyAmountComponent,
  InstrumentAmountComponent,
  InstrumentPriceComponent,
  NoNumericSpinDirective,
  NumberFormatDirective,
  TabaPayAddCardComponent,
];

@NgModule({
  declarations: exportedComponents.concat(NumericDisplayComponent),
  imports: [CommonModule],
  exports: exportedComponents,
})
export class CzModule {}
