<div class="flex-page">
  <div *ngIf="showHeaderFooter" class="flex-header">
    <app-header [theme]="theme" [simple]="simple" (themeChange)="themeChange($event)"></app-header>
  </div>
  <div toastContainer></div>

  <div class="flex-main fit-x">
    <router-outlet (activate)="onActivate()"></router-outlet>
  </div>

  <div *ngIf="showHeaderFooter" class="flex-footer">
    <app-footer></app-footer>
  </div>
</div>
